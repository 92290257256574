import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

export default function IconLabelButtons (props) {
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      width: props.widthHeight[0] + '%',
      height: props.widthHeight[1] + 'px',
      color: '#fff',
      borderRadius: 7,
      border: 'none',
      backgroundColor: '#05CE78',
      textTransform: 'uppercase',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin:0,
      marginTop:20,
    },
    text: {
      fontFamily: 'Roboto-Bold',
      fontSize: '22px',
      letterSpacing: '.88px',
      marginLeft:'5px',
    },
    outlineBtn: {
      margin: theme.spacing(1),
      width: props.widthHeight[0] + 'px',
      height: props.widthHeight[1] + 'px',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      color: '#818E94'
    }
  }))

  const classes = useStyles()
  return (
    <>
      {props.variant !== 'outlined' ? (
        <button onClick={props.onClick} className={classes.button}>
          {props.icon ? props.icon : ''}{' '}
          <span className={classes.text}>{props.title}</span>
          <span> </span>
        </button>
      ) : (
        <Button
          onClick={props.onClick}
          variant='outlined'
          color='inherit'
          className={classes.outlineBtn}
          style={props.style}
        >
          {props.icon ? props.icon : ''}{' '}
          <span style={props.style}>{props.title}</span>
          <span> </span>
        </Button>
      )}
    </>
  )
}

// <Button
// title={"Login To Deenconnect"}
// widthHeight={[180, 50]}
// // onClick={functionCall}
// variant="outlined"
// icon={<ArrowBackIcon />}
// />
