import React, { Component } from 'react'

import Logo from '../../assets/img/kiosk_logo.png';
import { getOTP, isDeviceVerified } from './Apis/index'
import './style.css'
import { updateuserToken, orgId } from '../../store/users/actions'
import { connect } from 'react-redux'

class onBoarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCodeExpired: false,
            otp: '',
            minutes: 5,
            seconds: 0,
            deviceId : ""
        }
    }
    async componentDidMount() {
        const url_string = window.location.href
        const url = new URL(url_string);
        const deviceId = url.searchParams.get("deviceId");
        let code_OTP = ""

        try {
            code_OTP = await getOTP(deviceId)
            this.setState({ otp: code_OTP.data.body.data , deviceId}, () => {
                this.startTimer()
            })
        }
        catch (e) {
        }

        try {
            if(deviceId) {
                const checkVerification = await isDeviceVerified(deviceId);
                let afterVerificationData = checkVerification?.data?.body?.data;
                if (afterVerificationData?.device?.status?.toLowerCase() === 'live' && afterVerificationData?.device?.isVerified === true) {
                    this.props.updateuserToken(afterVerificationData?.token)
                    this.props.orgId(afterVerificationData?.device?.orgId)
                    localStorage.setItem('deviceId', deviceId)
                    this.props.history.push("/home")
                } else {
                    this.checkVerificationInterval(code_OTP , deviceId)
                }
            }
        } catch (error) {
            
        }
    }

    componentWillUnmount(){
        clearInterval(this.checkInterval)
    }
    startTimer() {
        this.myInterval = setInterval(() => {
            const { seconds, minutes, isCodeExpired } = this.state
            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                    this.setState({ isCodeExpired: !isCodeExpired })
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    async checkVerificationInterval(code_OTP , deviceId){
        let checkVerification = ""
        if (deviceId) {
            try {
                const userRes = await fetch('https://ipinfo.io/json?token=989c1db047c5cf');
                let userData = await userRes.json();

                let date = new Date();
                let now = Date.parse(date.toISOString());
                // console.log("country_code", userData.ip);

                this.checkInterval = setInterval(async () => {
                    checkVerification = await isDeviceVerified(deviceId, userData?.ip, now)
                    let afterVerificationData = checkVerification?.data?.body?.data
                    // console.log("afterVerificationDatas", afterVerificationData)
                    if (afterVerificationData?.device?.status?.toLowerCase() === 'active') {
                        clearInterval(this.checkInterval)
                        this.props.updateuserToken(afterVerificationData?.token)
                        this.props.orgId(afterVerificationData?.device?.orgId)
                        this.props.history.push("/onboarding")
                        localStorage.setItem('deviceId',deviceId)
                    }
                }, 10000)

            }
            catch (e) {
            }
        }
    }

    async getNewCode() {
        const { isCodeExpired , deviceId} = this.state;
        let code_OTP = ""
        try {
            code_OTP = await getOTP(deviceId)
            this.setState({
                otp: code_OTP.data.body.data,
                isCodeExpired: !isCodeExpired,
                minutes: 5
            }, () => {
                this.startTimer()
            })
        }
        catch (e) {
        }
        this.checkVerificationInterval(code_OTP , deviceId)
    }
    componentWillUnmount() {
        clearInterval(this.myInterval)
        clearInterval(this.checkInterval)
    }
    render() {
        const { isCodeExpired, otp, minutes, seconds } = this.state;
        return (
            <div className='onboardingbg'>
                <div className='on_boarding_main'>
                    <div className='login_message'>
                        <div>
                            <h1><img src={Logo} alt="Logo" /></h1>
                        </div>
                        <div>
                            <p className='link'><span className='headingText'>Go to </span>https://kiosk.deenconnect.io</p>
                            <p className='headingText'>add this device using the code below</p>
                        </div>
                    </div>
                    <div className='on_boarding_otp'>
                        <div>
                            <h1 className={`${isCodeExpired ? 'opt_number_expired' : 'opt_number'}`}>{otp}</h1>
                        </div>
                        {!isCodeExpired && <div>
                            <p>Code expires in</p>
                            <p>{minutes}:{seconds < 10 ? `0${seconds}` : seconds} MIN</p>
                        </div>}

                        {isCodeExpired && <div>
                            <p>CODE EXPIRED</p>
                        </div>}
                    </div>

                    {isCodeExpired && <div className='get_new_code_btn'>
                        <span onClick={() => this.getNewCode()}>Get new code</span>
                    </div>}
                </div>

            </div>
        )
    }
}


function mapDispatchtoProps(dispatch) {
    return {
        updateuserToken: (token) => dispatch(updateuserToken(token)),
        orgId: (id) => dispatch(orgId(id)),
    }
}
export default connect(null, mapDispatchtoProps)(onBoarding)
