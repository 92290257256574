import React, { Component } from 'react'
import { Button, DonationIndex, TermOfService } from '../../components'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import './style.css'
import { connect } from 'react-redux'
import { isHideButton, setSteps, updateٖٖٖٖٖٖٖFee, updateAmount, clearState, showTos_PP } from '../../store/payment/actions'
import { clearUserFields } from '../../store/users/actions'
class Donation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      goBack: false
    }
    // this.goBack = this.goBack.bind(this);
  }

  // goBack() {
  //   this.setState({ goBack: true }, () => {
  //     this.setState({goBack: false});
  //   });
  // }

  render() {
    const { goBack } = this.state
    const { step, amount, total, fee, show_ToS } = this.props
    return (
      <div className='donation-container'>
        <div className="kiosk_container">

          {this.props.isHideButton && <div className='btn-container'>
            {!show_ToS.bool && <Button
              title='GO BACK'
              widthHeight={[270, 70]}
              variant='outlined'
              icon={<ArrowBackIcon />}
              onClick={() => {
                this.props.setSteps(step === 1 ? 1 : step - 1)
                step === 1 && this.props.history.replace('/home')
                step === 1 && this.props.clearUserFields()
                step === 1 && this.props.clearState()
                this.props.updateٖٖٖٖٖٖٖFee(false)
                fee && this.props.updateAmount(parseFloat(amount - total))
              }
              }
            // onClick={this.goBack}
            />}

            {!show_ToS.bool && <Button
              title='Home'
              widthHeight={[270, 70]}
              variant='outlined'
              onClick={() => {
                this.props.setSteps(1)
                this.props.history.push('/home')
                this.props.updateٖٖٖٖٖٖٖFee(false)
                fee && this.props.updateAmount(parseFloat(amount - total))
                this.props.clearUserFields()
                this.props.clearState()

              }}
              icon={<HomeRoundedIcon />}
            />}

             {show_ToS.bool && <Button
              title='GO BACK'
              widthHeight={[270, 70]}
              variant='outlined'
              icon={<ArrowBackIcon />}
              onClick={() => {
                this.props.setSteps(3)
                this.props.showTos_PP({bool : false , index : 0})
              }}
            />}

          </div>}
          {!show_ToS.bool && <DonationIndex onBackPressed={goBack} steper nav={this.props} />}
          {show_ToS.bool && <TermOfService index={show_ToS.index} />}
          {/* <TermOfService /> */}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isHideButton: state.payment.isHideButton,
    step: state.payment.step,
    amount: state.payment.amount,
    total: state.payment.total,
    fee: state.payment.fee,
    show_ToS: state.payment.show_ToS
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setSteps: (val) => dispatch(setSteps(val)),
    updateٖٖٖٖٖٖٖFee: (boolean) => dispatch(updateٖٖٖٖٖٖٖFee(boolean)),
    updateAmount: (val) => dispatch(updateAmount(val)),
    showTos_PP: (boolean) => dispatch(showTos_PP(boolean)),
    clearUserFields: () => dispatch(clearUserFields()),
    clearState: () => dispatch(clearState()),



  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Donation)