import React, { useEffect, useState, useRef } from "react";

import Card from "./Card";
import "./style.css";
import "./dailyReminder.css";

export default function DailyReminder(props) {
  const containerRef = useRef(null);
  var slider;
  useEffect(() => {
    const parent = containerRef.current;
    containerRef.current.id = "fadeOut"
    const childs = parent.getElementsByClassName("mySlides");
    if (props?.bookName) {
      showSlides(childs, parent);
    }
    return () => clearTimeout(slider)
  });
  let slide = 0;
  const showSlides = (childs, parentDiv) => {
    let i;
    if (childs && childs.length > 0) {
      for (i = 0; i < childs.length; i++) {
        childs[i].style.display = "none";
      }
      slide++;
      if (slide > childs.length) {
        slide = 1;
      }
      childs[slide - 1].style.display = "block";
      parentDiv.id = ""
      slider = setTimeout(() => {
        showSlides(childs, parentDiv);
      }, 6000); // Change image every 6 seconds
    }
  };

  const { quote, bookName } = props;
  return (
    <Card title="Daily Reminders" showFooter={false}>
      <div className="ev_cont center slider" ref={containerRef}>
        {quote &&
          quote.map((item , index) => {
            return <p key={index} className="mySlides">{item}</p>;
          })}
        {/* <label className="book_name">{bookName}</label> */}
      </div>
    </Card>
  );
}
