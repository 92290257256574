import React, { Component } from 'react'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import './footer.css'
import { connect } from 'react-redux'
import { updateuserToken, orgId, orgData ,isHeader} from '../../store/users/actions'


class Footer extends Component {

  componentDidMount() {

    if (window.location.pathname === '/' || window.location.pathname === '/onboarding'
     || window.location.pathname === '/onboarding/welcome' || window.location.pathname === '/pause') {
      this.props.isHeader(false)
    } else {
      this.props.isHeader(true)
    }
  }
  render() {
    return (
      <>
        {this.props.showHeader && <div className='footer-background'> </div>}
        {this.props.showHeader && <div className='footer'>
          <div className="footer_btm_logo"><LockOutlinedIcon /> <span>SECURED BY AWS</span></div>
          <p className='footer-text'>POWERED BY DEENCONNECT</p>
        </div>}
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    orgdata: state.users.orgdata,
    showHeader : state.users.showHeader
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateuserToken: (token) => dispatch(updateuserToken(token)),
    orgId: (id) => dispatch(orgId(id)),
    orgData: (orgdata) => dispatch(orgData(orgdata)),
    isHeader: (boolean) => dispatch(isHeader(boolean)),


  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer)
