import React, { Component } from 'react'
import { Button } from '../../components'
import imgtick from '.././../assets/rawimg/success_icon.png'
import Grid from '@material-ui/core/Grid'
import './css/Receipt.css'
import { connect } from 'react-redux'

class Receipt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: ''
    }

  }
  render() {
    const { width } = this.state
    const { totalCharges, frequency, shortName, paymentData } = this.props  
  return (
      <>
        <div className="succcess_page">
          <div style={{ alignSelf: 'center' }}>
            <center >
              <p
                style={{
                  // color: '#545268',
                  fontFamily: 'Roboto-Regular',
                  fontSize: '24px'
                }}
              >
                <img src={imgtick} width='108px' height='108px' />
                {/* <p className='right_col_title'>Donation Successful</p> */}
                <p className='Donation-Successful' > Donation Successful </p>
              </p>



              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="success_text">
                  <p>We greatly appreciate your generous</p>
                  {' '}<span style={{ textTransform: 'lowercase' }}>{frequency}</span> donation of {totalCharges} to {shortName}
                   
                  <div className="don_number">
                    {paymentData && paymentData.receipt_number &&  `Your donation confirmation number is`}
                    <span className="don_number_ref"> {paymentData && paymentData.receipt_number && paymentData.receipt_number} </span>
                  </div>

                  <div className="jazak_Allah">
                    Jazak-Allahu Khayran
                  </div>
                </div>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                
                  <div className="submit_btn btn_donate" style={{ textAlign: 'center' }}>
                    <Button
                      // disabled={!isValid}
                      onClick={() => this.props.onClick()} // this.stripTokenApiHit()
                      title='HOME'
                      widthHeight={[100,50]}
                      icon={<i class='fas fa-home' />}
                      className="checkout"
                      customColor={this.props.customColor ? this.props.customColor : '#05CE78'}
                    />
                  </div>
                </Grid>
              </Grid>
            </center>
          </div>
        </div>

      </>
    )
  }
}

export default Receipt;


