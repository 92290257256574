// import React from 'react'
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import './style.css';

let themeColor = '';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(0.5),
      width: '100%',
      display: 'flex'
    },
    
  },
  fieldText: {
    display: 'flex',
    fontFamily: 'Roboto-Regular',
    fontSize: '24px',
    color: 'red',
    marginTop: '20px',
    margin: "0",
    marginTop: 30,

    
    
  },
  htext: {
    display: 'flex',
    fontFamily: 'Roboto-Regular',
    fontSize: '16px',
    color: '#D3D8DA',
    textIndent: '5px',
    paddingTop: 0,
    marginLeft: 0,
    position:"relative",
    top:"5px",
  },
  etext: {
    display: 'flex',
    fontFamily: 'Roboto-Regular',
    letterSpacing: 0.4,
    color: 'red !important',
    fontSize: 14,
    textIndent: '5px',
    paddingTop: 0,
    marginLeft: 2
  },
  
}))



// const CssTextField = withStyles({
//   root: {
//     '& label.Mui-focused': {
//       color: '#7266FB',
//     },
//     '& .MuiInput-underline:after': {
//       borderBottomColor: '#7266FB',
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         // borderColor: '#7266FB',
//       },
//       '&:hover fieldset': {
//         // borderColor: '#7266FB',
//       },
//       '&.Mui-focused fieldset': {
//         borderColor:  '#7266FB',
//       },
//     },
//   },
// })(TextField);





 function BasicTextFields(props) {



  const [labelFlag, setlabelFlag ] = useState('')



  const classes = useStyles()
  return (
  <form className={classes.root} noValidate autoComplete='off'>
    <TextField
      onClick={() => setlabelFlag(true)}
      error={props.errorText && true}
      variant="outlined"
      placeholder={props.placeholder}
      className={classes.fieldText}
      type={props.type}
      id='standard-required'
      disabled={props.disabled}
      label={<span className={props.errorText === 'Invalid email address' && 'EmailfloatingLabel' || props.errorText && labelFlag && 'floatingLabel' }>
        {props.label}
      </span>}
      style={props.style}
      onChange={props.onChange}
      onBlur={() => {
        props.onBlur && props.onBlur()
        !props.errorText && setlabelFlag(true)
      }}
      value={props.value}
      onKeyDown={props.onKeyDown}
      autoFocus={props.autoFocus}
      InputProps={{
        endAdornment: <InputAdornment position="end">{props.icon}</InputAdornment>,
        inputMode: props.inputMode,
      }}
      focused={props.focused}
      
    />
    <span className="htext">{props.helperText}</span>
    <span className="etext">{props.errorText}</span>
  </form>
  )
}

export default BasicTextFields