import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '10px'
  },
  InputDiv: {
    alignSelf: 'center',
    width: '100%',
    borderRadius: '8px',
    display: 'flex'
  },
  dollorIcon: {
    width: '70px',
    height: '70px',
    backgroundColor: '#EEEEEE',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '32px',
    border: '1px solid #EEEEEE',
    fontFamily: 'Roboto-Regular'
  },
  inputAmount: {
    width: '100%',
    height: '70px',
    textIndent: '10px',
    border: '1px solid #D3D8DA',
    fontSize: '40px',
    fontFamily: 'Roboto-Regular',
    fontWeight: 500,
    borderBottomRightRadius: "4px",
    borderTopRightRadius: "4px",
  },
  mainDiv:{
    display : 'flex',
    flexDirection : 'column'
  },
  etext: {
    display: 'inline-block',
    fontFamily: 'Roboto-Regular',
    letterSpacing: 0.4,
    color: 'red',
    fontSize: 16,
    paddingTop: 4,
    position: 'relative',
    left: '70px',
    textAlign: 'left',
    width: '100%'


  }
}))

export default function InputAdornments(props) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.mainDiv}>
        <div className={classes.InputDiv}>
          <div className={classes.dollorIcon}>{props.leftText}</div>
          <input
            style={props.style}
            className={classes.inputAmount}
            typey='Text'
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
          />
        </div>
       {props.errorText&& <div>
          <span className={classes.etext}>{props.errorText}</span>
        </div>}
      </div>

    </div>
  )
}
