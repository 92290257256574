import React, { Component } from 'react'
import { Donor, Payment, Donation, Receipt, Stepper } from '../../components'
import { connect } from 'react-redux'
import { isHideButton , setSteps , clearState} from '../../store/payment/actions'

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      ispaymentDone: false,
      shortName: '',
      paymentData: {},
    }

    this.handleForNextStep = this.handleForNextStep.bind(this)
    this.finishPayment = this.finishPayment.bind(this)
  }

  handleForNextStep() {
    const { step} = this.props
    this.props.setSteps(step + 1)
  }

  finishPayment(data) {
    this.setState({
      ispaymentDone: true,
      paymentData: data,
    })

  }
  pervStep() {
    const { step } = this.state
  }

  render() {
    const { ispaymentDone, paymentData } = this.state;
    const { page, payment, home, steper, step, orgdata} = this.props;
    const { amount, donationType, fee  } = payment;

    const embedFormData = orgdata?.[0]?.enabledIntegrations?.deenfund?.embedForm?.length ? orgdata[0].enabledIntegrations.deenfund.embedForm[0] : {};

    // console.log("embedFormData",embedFormData)

    // const dfFee = orgdata && orgdata.platformFeeDiscount ? 0.0175 * (orgdata.platformFeeDiscount / 100) : 0.0175
    // const calculation = amount >= 5 ? (fee ? (+amount + 0.3 + (+amount * dfFee)) / 0.971 : + amount) : 0;
    const customColor = '';
    const shortName = orgdata?.[0]?.orgShortName

    return (
      <>
        {!ispaymentDone ? <div
          className={
            page === 'home' ? 'donation-contianer-home' : 'donation-contianer'
          }
        >
          <div className='donation-card'>
            {steper && <Stepper step={step}/>}

            {step === 1 && (
              <Donation
                homeNav={this.props.homeNav}
                page={page}
                home={home}
                embedFormData={embedFormData}
                onForNextStep={this.handleForNextStep}
              />
            )}

            {step === 2 && <Donor onForNextStep={this.handleForNextStep} />}

            {step === 3 && <Payment finishPayment={this.finishPayment} onForNextStep={this.handleForNextStep} />}
          </div>
        </div> :
          <Receipt
            onClick={() =>
              {
                this.props.isHideButton(true)
                this.props.nav.history.push('/home')
                this.props.setSteps(1)
                this.props.clearState()
            }
          }
            totalCharges={amount ? '$' + amount.toFixed(2) : '$' + amount}
            frequency={donationType}
            shortName={shortName}
            paymentData={paymentData}
            customColor={customColor}
          />
        }
      </>
    )
  }
}


function mapStateToProps(state) {
  return {
    payment: state.payment,
    step : state.payment.step,
    orgdata : state.users.orgdata
  }
}

function mapDispatchToProps(dispatch) {
  return {
    isHideButton:(btnBoolen) => dispatch(isHideButton(btnBoolen)),
    setSteps:(val) => dispatch(setSteps(val)),
    clearState:() => dispatch(clearState()),

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Home)
