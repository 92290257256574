import React, { Component } from 'react'
import { Button, SalahTable } from '../../components'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import './style.css'

export default class Salah extends Component {
  render () {
    return (
      <div className='bg'>
        <div className='kiosk_container'>
        <div className='salah-container'>
          <div style={{ marginRight: 35, }}>
          <div className='btn_back_innrepage'>
            <Button
              title='GO BACK'
              style={{ fontSize: '22px', backgroundColor: '#fff', color: '#7266FB', fontFamily: 'Roboto-Medium',}}
                widthHeight={[270, 70]}
              onClick={() => this.props.history.goBack()}
              variant='outlined'
              icon={<ArrowBackIcon style={{ fontSize: '28px' }} />}
            />
            </div>
          </div>

          <div>
            <div className='salah-nav-header'>
              <div class='page_link'>
                <a href='#'>{<HomeRoundedIcon />} Home</a>
                <span><i className='fas fa-chevron-right' /></span>
                <a href='#'>SALAH TIME</a>
              </div>
            </div>
            <div className='card table-background'>
              <SalahTable />
            </div>
          </div>
        </div>
        </div>
      </div>
      
    )
  }
}
