import React, { Component } from 'react'
import { Button, Input } from '../../components'
import LockIcon from '@material-ui/icons/Lock'
import './style.css'
import { login, organizations } from '../Donation/apis/index'
import { updateuserToken, orgId, orgData, isHeader } from '../../store/users/actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
class LoginCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      emailErr: false,
      isLoading: false,
      isError: false,
      isApiError: false,
      isPasswordError: false,
      isEmailError: false

    }
  }

  async login() {
    const { email, password } = this.state;
    this.setState({ isLoading: true, isError: false, isApiError: false })
    if (email && password) {
      const userLogin = {
        userName: email,
        password: password
      }
      try {
        const res = await login(userLogin)
        if (res && res.status) {
          this.setState({ isLoading: true })
          const token = res.data.body.data.token
          const orgId = res.data.body.data.defaultOrg
          if (orgId) {
            const res = await organizations(orgId, token)
            if (res) {
              this.setState({ isLoading: false })
              this.props.orgData(res.data.body.data)
              this.props.updateuserToken(token)
              this.props.orgId(orgId)
              this.props.isHeader(true)
              this.props.history.push('./home')
            }
          }
        }
      }
      catch (e) {
        this.setState({ isLoading: false, isApiError: true , email : '' , password : '' })
        setTimeout(() => {
          this.setState({ isApiError: false })

        }, 5000)
      }

    } else {
      if (email === '') {
        this.setState({ isLoading: false, isEmailError: true })
      } else {
        this.setState({ isLoading: false, isEmailError: false })
      }

      if (password === '') {
        this.setState({ isLoading: false, isPasswordError: true })
      } else {
        this.setState({ isLoading: false, isPasswordError: false })
      }


    }
  }

  render() {
    const { emailErr, isLoading, isError, isApiError , isEmailError , isPasswordError } = this.state
    return (
      <div
        className='login-card'
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div className='sub-container login_container'>
          <center>
            <p style={{ margin: '20px auto', fontSize: '24px', color: '#000' }}>
              <span className='login'>Enter Your DEENCONNECT</span>
            </p>
          </center>

          <Input label='Email'
            type='text'
            value={this.state.email}
            // helperText='Helper text'
            errorText={(emailErr && 'Email is not correct')}
            onFocus={()=> this.setState({isEmailError : false})}
            onChange={(e) => {
              this.setState({isEmailError : false , email : e.target.value})
              const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              if (e.target.value.match(emailReg)) {
                this.setState({ email: e.target.value , isEmailError : false  })
              }
            }}


          // onBlur={() => {
          //   if (!this.state.email) {
          //     this.setState({ emailErr: true })
          //   } else {
          //     this.setState({ emailErr: false })

          //   }
          // }}

          />
           {isEmailError === true && this.state.email.length > 0 && <div style={{ marginTop: 20, marginBottom: 20, textAlign: 'center', }}>
            <p style={{ color: 'red', lineHeight: '5px', fontSize: 16, marginLeft: -1 }} className="login_error">
              Invalid email
              </p>
            </div>}

            {isEmailError === true && !this.state.email && <div style={{ marginTop: 20, marginBottom: 20, textAlign: 'center', }}>
            <p style={{ color: 'red', lineHeight: '5px', fontSize: 16, marginLeft: -1 }} className="login_error">
              Email is required
              </p>
            </div>}
          <Input
            label=' Enter Password'
            type='password'
            value={this.state.password}
            // helperText='Helper text'
            onChange={(e) => {
              this.setState({ password: e.target.value , isPasswordError : false })
            }}
          />

          {isPasswordError && <div style={{ marginTop: 20, marginBottom: 20, textAlign: 'center', }}>
            <p style={{ color: 'red', lineHeight: '5px', fontSize: 16, marginLeft: -1 }} className="login_error">
              Password is required
              </p>
          </div>}

          {isApiError && <div style={{ marginTop: 20, marginBottom: 20, textAlign: 'center', }}>
            <p style={{ color: 'red', lineHeight: '5px', fontSize: 18 }}>
              We could not find the resource you requested. try again
              </p>
          </div>}

          <div style={{ marginTop: '10px', textAlign: 'center' }} className="login_btn">
            {!isLoading ? <Button
              title='Login To Deenconnect'
              widthHeight={[100, 60]}
              icon={<LockIcon />}
              onClick={() => this.login()}
            /> : <CircularProgress />}
          </div>


          <div style={{ marginTop: '5px' }}>
            <p
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '15px',
                fontSize: '16'
              }}
            >
              <a href='#' style={{ color: '#818E94', fontSize: '16px', }}>
                Forget Password?
              </a>
              <p style={{ color: '#818E94', lineHeight: '5px', fontSize: '16px', marginTop: '10px', }}>
                Please reset the from the web-base DEENCONNECT Console
              </p>
            </p>
          </div>
        </div>

        <div className="loging_aws_logo">
          <div className="SECURED_BY_AWS"><LockOutlinedIcon /> <span>SECURED BY <label>AWS</label></span></div>
        </div>

      </div>
    )
  }
}
function mapStateToProps(state) {
  return {

  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateuserToken: (token) => dispatch(updateuserToken(token)),
    orgId: (id) => dispatch(orgId(id)),
    orgData: (orgdata) => dispatch(orgData(orgdata)),
    isHeader: (showHeader) => dispatch(isHeader(showHeader))

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginCard))
