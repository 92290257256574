import React from 'react'
import Navigation from './navigations'
import { Provider } from 'react-redux'
import { Header, Footer } from './components'
import store, { persistor } from './store'
import { PersistGate } from "redux-persist/integration/react";
import './App.css'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Header />
        <Navigation />
        <Footer />
      </PersistGate>
    </Provider>
  )
}

export default App
