import React, { Component } from 'react'
import LockIcon from '@material-ui/icons/Lock'

import { Button } from '..'

import { connect } from 'react-redux'
import './style.css'

class PhoneVerification extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isCode: false,
      title: this.props.eptype.title,
      code: [],
      convertCode: ''
    }

    this.handleCode = this.handleCode.bind(this)
    this.isUserCheck = this.isUserCheck.bind(this)
    this.checkCodeMatching = this.checkCodeMatching.bind(this)
    this.codeGet = this.codeGet.bind(this)
  }

  handleCode () {
    this.setState({
      isCode: true,
      title: 'Enter Verification Code'
    })
  }

  isUserCheck (verifyObj) {
    const { user, email, phoneNumber } = this.props

    if (email) {
      if (user.email === email) {
        this.handleCode()
      } else {
        alert('Email Not email')
      }
    }

    if (phoneNumber) {
      if (user.connactNumber === phoneNumber) {
        this.handleCode()
      } else {
        alert('Email Not Match Phone Number')
      }
    }
  }

  checkCodeMatching () {
    const codeOfApi = '1234'
    const { convertCode } = this.state
    if (codeOfApi === convertCode) {
      alert('matc')
    } else {
      alert('Not Match')
    }
  }

  codeGet (value) {
    const { code } = this.state
    code.push(value)
    const convertCode = code.join('')
    this.setState({ convertCode: convertCode })
  }

  render () {
    const { isCode, title } = this.state
    const { eptype } = this.props
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <div class='right_col_title' style={{ width: '100%' }}>
            <center>
              <b>{title}</b>
              <br />
              {!isCode && (
                <snap className='verifyPhone-text'>{eptype.description} </snap>
              )}
            </center>
          </div>
          {!isCode && (
            <div style={{ alignSelf: 'center' }}>
              {' '}
              {this.props.inputField}
              <br />
              <Button
                title='Send Verification Code'
                widthHeight={[510, 60]}
                icon={<LockIcon />}
                onClick={() => this.isUserCheck()}
              />{' '}
            </div>
          )}
        </div>

        {isCode && (
          <>
            <center>
              <p style={{ color: '#B8C0C3', lineHeight: '25px' }}>
                Please enter the verification code sent to your{' '}
                {eptype.type === 'email' ? 'email address' : 'phone number'}
                <br /> <a href='#'>{eptype.userContact}</a>
              </p>
            </center>
            <div className='enter_number'>
              <div class='form-codeInput'>
                <input
                  type='text'
                  class='form-control'
                  id='usr'
                  placeholder='0'
                  maxlength='1'
                  size='1'
                  onChange={e => this.codeGet(e.target.value)}
                />
                <input
                  type='text'
                  class='form-control'
                  id='usr'
                  placeholder='0'
                  maxlength='1'
                  size='1'
                  onChange={e => this.codeGet(e.target.value)}
                />
                <input
                  type='text'
                  class='form-control'
                  id='usr'
                  placeholder='0'
                  maxlength='1'
                  size='1'
                  onChange={e => this.codeGet(e.target.value)}
                />
                <input
                  type='text'
                  class='form-control'
                  id='usr'
                  placeholder='0'
                  maxlength='1'
                  size='1'
                  onChange={e => this.codeGet(e.target.value)}
                />
              </div>
            </div>
            <br />
            <Button
              title='Confrim'
              widthHeight={[510, 60]}
              icon={<LockIcon />}
              onClick={() => this.checkCodeMatching()}
            />{' '}
            <div class='join_deen mrg_top'>
              Didn’t receive the code? <a href='#'>Resend Code</a>
            </div>
          </>
        )}
      </>
    )
  }
}

function mapStateToProps (state) {
  return {
    user: state.users.user
  }
}

export default connect(mapStateToProps, null)(PhoneVerification)
