import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import Logo from '../../assets/img/kiosk_logo.png';

import './style.css'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

class onBoarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSettingUpDevice: false
        }
    }
    componentDidMount() {
        const { isSettingUpDevice } = this.state;
        setTimeout(() => {
            this.setState({ isSettingUpDevice: !isSettingUpDevice }, ()=> {
                if(this.state.isSettingUpDevice){
                    setTimeout(()=> {
                        this.props.history.push("/onboarding/welcome")
                    }, 4000)
                }
            }, 4000)
            })
           
        
    }
    render() {
        const { isSettingUpDevice } = this.state;
        return (
            <div className='onboardingbg'>
                <div className='on_boarding_main'>
                    <div className='login_message'>
                        <div>
                            <h1><img src={Logo} alt="Logo" /></h1>
                        </div>
                        {!isSettingUpDevice && <div>
                            <p className='headingTextFirst'>We're adding your device</p>
                            <p className='headingText'>Almost done...</p>
                        </div>}
                        {isSettingUpDevice && <div>
                            <p className='headingTextFirst'>You're all set.</p>
                            <p className='headingText'>Thank you for waiting!</p>
                        </div>}
                    </div>
                    <div className={useStyles.root} style={{position : 'relative' , height : '530px' , top : '520px'}}>
                        <LinearProgress />
                    </div>
                </div>

            </div>
        )
    }
}
export default onBoarding;
