import axios from 'axios'

const envVariabels = {
  prod: {
    baseUrl: process.env.REACT_APP_BASEURL_PROD_API,
    paymentUrl: process.env.REACT_APP_PAYMENT_PROD_API
  },
  dev: {
    baseUrl:  process.env.REACT_APP_BASEURL_DEV_API,
    paymentUrl: process.env.REACT_APP_PAYMENT_DEV_API
  },
  local: {
    baseUrl: process.env.REACT_APP_BASEURL_API_LOCAL,
    paymentUrl: process.env.REACT_APP_PAYMENT_API_LOCAL
  }
};

// const env = {
//   prod: process.env.REACT_APP_PAYMENT_PROD_API,
//   dev: process.env.REACT_APP_PAYMENT_DEV_API
// }

// const sharedEnv = {
//   prod: process.env.REACT_APP_BASEURL_PROD_API,
//   dev: process.env.REACT_APP_BASEURL_DEV_API
// }
// let currentEnv
// if (process.env.REACT_APP_Project_Environment === 'localhost' || process.env.REACT_APP_Project_Environment === 'dev') {
//   currentEnv = 'dev'
// } else {
//   currentEnv = 'prod'
// }
// const paymentBaseUrl = env[currentEnv]
// const orgBaseUrl = sharedEnv[currentEnv]

const paymentBaseUrl = eval(`envVariabels.${process.env.REACT_APP_Project_Environment}.paymentUrl`);
const orgBaseUrl = eval(`envVariabels.${process.env.REACT_APP_Project_Environment}.baseUrl`);

export function login (user) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${paymentBaseUrl}/auth/authenticate`, user)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function stripTokenGet (cardInfo, chargeInfo) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${paymentBaseUrl}/stripe/token`, cardInfo)
      .then((res) => {
        if (res.data.body.data) {
          const { amount, orgId, phoneNo, description, frequency, categoryId,
            processingFee, memoNote, firstName, lastName, email,billing_details } = chargeInfo
          axios
            .post(`${paymentBaseUrl}/stripe/charges`, {
              amount: amount * 100,
              currency: 'USD',
              description,
              // source: '1',
              source: res.data.body.data,
              orgId,
              phoneNo,
              frequency,
              stream: 'DEENKIOSK',
              categoryId,
              processingFee,
              memoNote,
              firstName,
              lastName,
              email,
              billing_details
            })
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve(res)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function getSelectedDonationCategories(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/donation-category?orgId=${orgId}&application=deenfund`)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function getCategories (orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/donation-category?orgId=${orgId}`)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function organizations (orgId , token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/organizations/${orgId}` , {
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function salah (orgId , token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/salah?orgId=${orgId}`,{
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject({})
      })
  })
}

export function services (orgId , token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/services?orgId=${orgId}`,{
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject([])
      })
  })
}

export function reminders (orgId , token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/reminders?orgId=${orgId}`,{
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject({})
      })
  })
}

export function annoucements (orgId , token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/announcements?orgId=${orgId}`,{
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject({})
      })
  })
}
export function upcomingEvents(token, orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/digital-device-bridge/events?orgId=${orgId}` , {
        headers: {
          "Content-Type": "application/json",
          "token":token
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject({})
      })
  })
}

export function contactform(formObject) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/contact` , formObject)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function getHomeScreenPreviewById(id, token) {
  return new Promise((resolve, reject) => {
      axios
          .get(`${orgBaseUrl}/digital-device-bridge/getHomeScreenPreviewById/${id}`, {
              headers: {
                  token
              },
          })
          .then((res) => {
              resolve(res)
          })
          .catch((error) => {
              reject(error)
          })
  })
}
