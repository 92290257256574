import React, { Component } from 'react'
import LockIcon from '@material-ui/icons/Lock'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { updateFirstName, updateLastName, updateEmail, updateUserAsGuest, updateErrorFlag } from '../../store/users/actions'
import './style.css'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class TermOfService extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0

        }

    }

    componentDidMount(){
        this.setState({value : this.props.index})
    }

    render() {
        const { value } = this.state;
        const indexProps = this.props.index
        return (
            <>
                <div className='tos-contianer'>
                    <Paper square>
                        <Tabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(event, newValue) => this.setState({ value: newValue })}
                            aria-label="disabled tabs example"
                        >
                            <Tab label="Terms of Service" />
                            <Tab label="Privacy Policy" />
                        </Tabs>
                    </Paper>

                    {value === 0 && <section className='ToS'>
                        <div className='scrollbar'>
                            <div className='tos_pp_div'>
                                <h1>1. Information collection, use and sharing</h1>
                                <p>“The month of Ramadhan [is that] in which was revealed the Qur'an, a guidance
                                for the people and clear proofs of guidance and criterion. So whoever sights
                                [the new moon of] the month, let him fast it; and whoever is ill or on a journey -
                                then an equal number of other days. Allah intends for you ease and does not intend for you
                                hardship and [wants] for you to complete the period and to glorify Allah for that [to]
                            which He has guided you; and perhaps you will be grateful.” [2: 185] </p>
                                <p>Iftar services are provided for over hundreds of Muslims—brothers and sisters including children—daily throughout the blessed month of Ramadan. This provides a great opportunity to meet and bond with fellow Muslims within and outside the city, further strengthening the ties of brotherhood. Tarawih Prayers are also offered daily throughout the month,
                          performed by dynamic, young and experienced Hafizes with mesmerizing recitations.</p>
                            </div>

                            <div className='tos_pp_div'>
                                <h1>2. Changed terms</h1>
                                <p>Iftar services are provided for over hundreds of Muslims—brothers and sisters including children—daily throughout the blessed month of Ramadan. This provides a great opportunity to meet and bond with fellow Muslims within and outside the city, further strengthening the ties of brotherhood. Tarawih Prayers are also offered daily throughout the month, performed by dynamic, young and experienced Hafizes with mesmerizing recitations.</p>
                            </div>


                        </div>

                    </section>}

                    {value === 1 && <section className='ToS'>
                        <div className='scrollbar'>
                            <div className='tos_pp_div'>
                                <h1>1. Information collection, use and sharing</h1>
                                <p>“The month of Ramadhan [is that] in which was revealed the Qur'an, a guidance
                                for the people and clear proofs of guidance and criterion. So whoever sights
                                [the new moon of] the month, let him fast it; and whoever is ill or on a journey -
                                then an equal number of other days. Allah intends for you ease and does not intend for you
                                hardship and [wants] for you to complete the period and to glorify Allah for that [to]
                            which He has guided you; and perhaps you will be grateful.” [2: 185] </p>
                                <p>Iftar services are provided for over hundreds of Muslims—brothers and sisters including children—daily throughout the blessed month of Ramadan. This provides a great opportunity to meet and bond with fellow Muslims within and outside the city, further strengthening the ties of brotherhood. Tarawih Prayers are also offered daily throughout the month,
                          performed by dynamic, young and experienced Hafizes with mesmerizing recitations.</p>
                            </div>

                            <div className='tos_pp_div'>
                                <h1>2. Changed term</h1>
                                <p>Iftar services are provided for over hundreds of Muslims—brothers and sisters including children—daily throughout the blessed month of Ramadan. This provides a great opportunity to meet and bond with fellow Muslims within and outside the city, further strengthening the ties of brotherhood. Tarawih Prayers are also offered daily throughout the month, performed by dynamic, young and experienced Hafizes with mesmerizing recitations.</p>
                            </div>
                        </div>

                    </section>}
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermOfService)