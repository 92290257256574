import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import './style.css'
import { isDeviceVerified } from './Apis/index'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

class welcomeOnBoarding extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isSettingUpDevice: false
        }
    }
    async componentDidMount() {
        this._isMounted = true;
        const deviceId = localStorage.getItem('deviceId')
        const checkVerification = await isDeviceVerified(deviceId)
        const afterVerificationData = checkVerification?.data?.body?.data
        if (afterVerificationData?.device?.status === 'Live') {
            this.props.history.push("/home")
        }
        else {
            this.myInterval = setInterval(() => {
                    (async () => {
                        const checkVerification = await isDeviceVerified(deviceId)
                        const afterVerificationData = checkVerification?.data?.body?.data
                        if (afterVerificationData?.device?.status === 'Live') {
                            this.props.history.push("/home")
                            clearInterval(this.myInterval)
                        }
                    })()
            }, 10000)

        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.myInterval)
    }
    render() {
        return (
            <div className='onboardingbg'>
                <div className='on_boarding_welcome'>
                    <div className='welcome_message'>
                        <div>
                            <h1>Welcome to your new kiosk</h1>
                        </div>
                        <div>
                            <p className='headingText'>Follow these steps to make your kiosk live.</p>
                        </div>
                    </div>
                </div>
                <div className='welcome_child_boxes'>
                    <div>
                        <div className='box_number'><span>1</span></div>
                        <div className='box_heading'>Login to console</div>
                        <div className='box_para'>Login to your DEENCONNECT console from a web browser</div>
                    </div>
                    <div>
                        <div className='box_number'><span>2</span></div>
                        <div className='box_heading'>Add your content</div>
                        <div className='box_para'>Add your content from the console and save your changes</div>
                    </div>
                    <div>
                        <div className='box_number'><span>3</span></div>
                        <div className='box_heading'>Publish to Kiosk</div>
                        <div className='box_para'>Select the device you want to publish on and make your kiosk live</div>
                    </div>
                </div>
            </div>
        )
    }
}
export default welcomeOnBoarding;
