import React, { Component } from 'react'
import './style.css'
import { connect } from 'react-redux'
import moment from 'moment'
import Moment from 'moment-timezone'
import { withRouter } from 'react-router-dom'

import LoginCard from '../LoginCard'
class SalahTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Salahtable: [],
    }
  }

  componentDidMount() {
    const { salah } = this.props;
    if(salah?.length){
      let tempSalah= [... salah];
    // const filterSalah = salah.filter((item) => item.status === "active")
    // if (filterSalah) {
      // const sortedSalahs = salah.slice().sort((a, b) => this.getTimeStampclock(a?.waqt?.waqtDate, a?.waqt?.timezoneFormat) - this.getTimeStampclock(b?.waqt?.waqtDate, b?.waqt?.timezoneFormat))
      // const updatedAt = salah.sort((a, b) => this.getTimeStamp(b.updatedAt) - this.getTimeStamp(a.updatedAt))
      this.setState({ Salahtable: salah, updatedAt: tempSalah.sort((a, b) => this.getTimeStamp(b.updatedAt) - this.getTimeStamp(a.updatedAt))[0]?.updatedAt })
    // }
  }
  else {
     this.props.history.push('home')
  }
  }

  getTimeStampclock(time,timezoneFormat) {
    let i =  Moment(time).tz(timezoneFormat).format("HH:mm")
    return parseInt(i)
  }
  getTimeStamp(time) {
    // let date = moment(time, 'MMM DD,YYYY')
    let timestamp = moment(time).format("x");
    return timestamp
  }
  render() {
    const { Salahtable, updatedAt } = this.state
    return (
      <>
        <div className='slah_table'>
          <table className='table'>
            <thead className='thead-dark'>
              <tr>
                <th style={{ display: 'flex' }}>Salah Name</th>
                <th>Waqt</th>
                <th style={{ textAlign: 'right' }}>Iqamah Time</th>
              </tr>
            </thead>
            <tbody>
              {Salahtable && Salahtable.map((item, index) => {
                return <tr key={index}>
                  <td className='slah_name'>
                    {item.salahName.split(' (')[0]} <span className='salahList'>{`(${item.salahName.match(/\(([^)]+)\)/)[1]})`}</span>
                  </td>
                  <td className='wakt_time'>{item?.waqt?.waqtDate ? Moment(item.waqt.waqtDate).format('hh:mm A') : '-'}</td>
                  <td className='iqama_time'>{item?.salahStart?.iqamahTime && Moment(item.salahStart.iqamahTime).format("hh:mm A")}</td>
                </tr>
              })}
            </tbody>
          </table>
          <p className='Recent-update'>Recent Update - {Moment(updatedAt).format("MMMM DD, YYYY")}</p>
        </div>
      </>
    )
  }
}
function mapStateToProps(state) {
  return {

    salah: state.users.salah,

  }
}



export default connect(mapStateToProps, null)(withRouter(SalahTable))