import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Login, Home, Eventdetails, Donation, Salah, Services, Paused, ServiceDetails, Contact, About, Events ,welcomeOnBoarding, OnBoarding,addingOnBoarding } from '../screens'
import withContext from '../components/contextAPIS/context'
import HomePreview from '../screens/HomePreview'


function Navigations() {
  return (
    <Router>
       <Route exact path='/' component={OnBoarding} />
        <Route exact path='/onboarding' component={addingOnBoarding} />
        <Route exact path='/onboarding/welcome' component={welcomeOnBoarding} />
        <Route exact path='/pause' component={Paused} />
        <Route exact path='/donation' component={Donation} />
        <Route exact path='/home' component={withContext(Home)} />
        <Route exact path='/home-preview' component={withContext(HomePreview)} />
        <Route exact path='/salah' component={Salah} />
        <Route exact path='/services' component={Services} />
        <Route exact path='/services-details' component={ServiceDetails} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/about' component={About} />
        <Route exact path='/events' component={Events} />
        <Route exact path='/event-details' component={Eventdetails} />
    </Router>
  )
}

export default Navigations