import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { connect } from 'react-redux'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import { markdowntoreact } from '../../util/markdown'
import Button from '../../components/Button'
import './style.css'

function About(props) {
  const orgdata = props.orgdata && props.orgdata[0]
  const orgTitle = orgdata?.orgName
  const address = orgdata && orgdata?.orgAddress
  const about_org = orgdata?.aboutOrg
  return (
    <div className='bg'>
      <div className='kiosk_container'>
        <Grid container spacing={0}>
          <Grid item lg={2}>
            <div className='btn_back_innrepage'>
              <Link className='linkStyle' to='/home'>
                <Button
                  title='GO BACK'
                  style={{ fontSize: '22px', backgroundColor: '#fff', color: '#7266FB', fontFamily: 'Roboto-Medium', }}
                  widthHeight={[270, 70]}
                  variant='outlined'
                  icon={<ArrowBackIcon />}
                />
              </Link>
            </div>
          </Grid>
          <Grid lg={4} style={{ marginTop: 0 }}>
            <div className='page_link'>
              <Link to='/home'>{<HomeRoundedIcon />} Home</Link>
              <span><i className='fas fa-chevron-right' /></span>
              <Link to='/about'>About Us</Link>
            </div>
            <div className='about_card'>
              <div className='about_title'>{orgTitle}</div>
              <div className='about_details'>

                <ul>
                  <li>
                    <img className='abt_info_icon' src={require('../../assets/rawimg/locations_icon.png')} />
                    <div className='abt_info'>
                      <h2>Address</h2>
                      <p>{address && address.line1} {address && address.line2}, <span>{address && address.state}, {address && address.city} {address && address.postalCode}</span></p>
                    </div>
                    <div className='clearfix' />
                  </li>

                  <li>
                    <img className='abt_info_icon' src={require('../../assets/rawimg/Phone_icon.png')} />
                    <div className='abt_info'>
                      <h2>Phone Number</h2>
                      <p>{orgdata && orgdata?.phoneFax?.primaryPhone}<span>{orgdata && orgdata?.phoneFax?.secondaryPhone}</span></p>
                    </div>
                    <div className='clearfix' />
                  </li>

                  <li>
                    <img className='abt_info_icon' src={require('../../assets/rawimg/Email_icon.png')} />
                    <div className='abt_info'>
                      <h2>Email Address</h2>
                      <p>{orgdata && orgdata?.orgEmail ?  orgdata?.orgEmail : ''}</p>
                    </div>
                    <div className='clearfix' />
                  </li>

                  <li>
                    <img className='abt_info_icon' src={require('../../assets/rawimg/website_icon.png')} />
                    <div className='abt_info'>
                      <h2>WEBSITE</h2>
                      <p>{orgdata && orgdata.orgWebsite ? orgdata.orgWebsite : '-'}</p>
                    </div>
                    <div className='clearfix' />
                  </li>

                </ul>
                <div className='clearfix' />
              </div>
            </div>
          </Grid>
          <Grid lg={6}>
            <div className='white_text'>
              <h3>About us</h3>
              {/* {about_org && about_org.about_us_short && <p>{about_org.about_us_short}</p>} */}
              {about_org && about_org.aboutUsLong && <p>{markdowntoreact(JSON.parse(about_org.aboutUsLong))}</p>}
              {about_org && about_org?.orgMission?.enabled &&
                <>
                  <h3>Mission</h3>
                  <p>{markdowntoreact(JSON.parse(about_org.orgMission.content))}</p>
                </>}

              {about_org && about_org?.orgVision?.enabled &&
                <>
                  <h3>Vision</h3>
                  <p>{markdowntoreact(JSON.parse(about_org.orgVision.content))}</p>
                </>}

            </div>
          </Grid>
        </Grid>

      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    orgId: state.users.orgId,
    orgdata: state.users.orgdata,
  }
}



export default connect(mapStateToProps, null)(About)
