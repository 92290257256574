import React, { Component } from 'react'
import { LoginCard } from '../../components'
import './style.css'

export default class Login extends Component {
  render () {
    return (
      <div class='login-container-div'>
        <LoginCard />
      </div>
    )
  }
}
