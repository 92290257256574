import React, { Component } from 'react'
import LockIcon from '@material-ui/icons/Lock'
import { Button, Input, Checkbox } from '../../components'
import './css/Payment.css'
import Grid from '@material-ui/core/Grid'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Tooltip from '@material-ui/core/Tooltip'
import InputAdornment from '@material-ui/core/InputAdornment'
import { connect } from 'react-redux'
import { stripTokenGet } from './apis'
import { updateErrorFlag, } from '../../store/users/actions'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress'
import { updateٖٖٖٖٖٖٖFee, isHideButton, setSteps, updateEDIT, isHomepage, updateAmount, updatetotalAmount, updateStripeAmount, updateAmountWithoutFee } from '../../store/payment/actions'
import { clearUserFields } from '../../store/users/actions'

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isProceed: true,
      check: '',
      title: 'Payment Information',
      cardNumber: '',
      cardHolderName: '',
      expMonth: '',
      expYear: '',
      cvvCode: '',
      amount: 0,
      error: false,
      errorMsg: '',
      cvvErrorMsg: '',
      zipCode: '',
      expDate: '',
      width: '',
      loading: false,
      value: '',
      token: true,
      tooltipFlag: false,
      expError: '',
      invalidCard : false,
      orgId: ''
    }
    this.proceesNext = this.proceesNext.bind(this)
    this.stripTokenApiHit = this.stripTokenApiHit.bind(this)
    this.handleExpDate = this.handleExpDate.bind(this)
  }

  componentDidMount() {
    const { fee } = this.props;
    this.setState({ check: fee })
  }

  proceesNext() {
    const { cardNumber, cardHolderName, expMonth, expYear, cvvCode, zipCode } = this.state
    if (!cardNumber || !cardHolderName || !expMonth || !expYear || !cvvCode || !zipCode) {
      this.setState({
        error: true
      })
    } else {
      if (cardNumber[0] === '3' ? cvvCode.length !== 4 : cvvCode.length !== 3) {
        this.setState({
          error: true,
          cvvErrorMsg: 'Enter valid CVV Code'
        })
        return
      }
      this.setState({
        error: false,
        isProceed: false,
        cvvErrorMsg: false,
        title: 'Your Billing Address'
      })
    }
  };

  async stripTokenApiHit() {
    const { cardNumber, cardHolderName, expMonth, expYear, cvvCode, expError ,zipCode} = this.state
    const { orgId } = this.props;
    const newNumber = cardNumber.replace(/ +/g, '')
    const {
      payment: { category, donationType: frequency, categoryId, fee, amount, note, stripeamount },
      userInfo: { firstName, lastName, email, phoneNo },
      amountwithoutfee
    } = this.props
    if (!cardNumber || !cardHolderName || !expMonth || !expYear || !cvvCode || !firstName || !lastName || !phoneNo || !categoryId || amount < 5) {
      this.setState({
        error: true
      })
      this.props.updateErrorFlag(true)
    } else if ((cardNumber[0] !== '3' && newNumber.length < 16) || (cardNumber[0] === '3' && newNumber.length < 15)) {
      this.setState({
        cardError: true
      })
    } else if ((cardNumber[0] === '3' && cvvCode.length < 4) || (cardNumber[0] !== '3' && cvvCode.length < 3)) {
      this.setState({
        error: true
      })
    } else {
      const cardInfo = {
        number: cardNumber.replace(/\s/g, ''),
        exp_month: expMonth,
        exp_year: +('20' + expYear),
        cvc: cvvCode
      }
      const chargeInfo = {
        amount: amountwithoutfee,
        orgId,
        phoneNo,
        description: `${frequency}/${category}`,
        frequency: frequency.replace(' ', '-'),
        categoryId,
        processingFee: fee,
        memoNote: note || ' ',
        firstName,
        lastName,
        email: email || 'test@bync.com',
        billing_details:{
          address:{
            postal_code:zipCode
          }
	        }
      }
      try {
        if (!this.state.token) {
        } else {
          this.setState({ loading: true })
          console.log("cardInfo", cardInfo)
          console.log("chargeInfo", chargeInfo)
          const res = await stripTokenGet(cardInfo, chargeInfo)
          this.setState({ loading: false })
          if (res.data.success) {
            this.props.finishPayment(res.data.body.data)
            this.props.isHideButton(false)
            this.props.clearUserFields()
            // this.props.hideAlert()
          } else {
            if (res.data.body.message === 'Server Error') {
              this.setState({
                invalidCard: true,
                error: false,
                isProceed: true,
                title: 'Card information',
                errorMsg: res.data.body.message
              })
            }
            else {
              this.setState({
                cardError: true,
                error: false,
                isProceed: true,
                title: 'Card information',
                errorMsg: ''
              })
            }

            // this.props.showAlert('error')
            // setTimeout(() => {
            //   this.props.hideAlert()
            // }, 5000)
          }
        }
      } catch (e) {
        this.setState({
          error: false,
          loading: false,
          isProceed: true,
          title: 'Card information'
          // errorMsg: 'Something went wrong! Please try again with valid information.'
        })

        // this.props.showAlert('error')
        // this.props.hideAlert()
        // setTimeout(() => {

        // },4000)
      }
    }
  }

  handleExpDate(e) {
    // ignore invalid input
    if (!/^\d{0,2}\/?\d{0,2}$/.test(e.target.value)) {
      return
    }
    let input = e.target.value
    if (/^\d{3,}$/.test(input)) {
      input = input.match(new RegExp('.{1,2}', 'g')).join('/')
    }
    const [expMonth, expYear] = input.split('/')
    if (expMonth > 12) return
    const expFullYear = '20' + expYear
    const date = new Date()
    this.setState({
      expError: expFullYear.length === 4 && expFullYear < 2020 && 'Invalid exp date'
    })
    if (+expFullYear === date.getFullYear()) {
      this.setState({
        expError: expMonth < date.getMonth() + 1 && 'Invalid exp date'
      })
    }
    this.setState({
      expDate: input,
      expMonth: Number(expMonth),
      expYear: Number(expYear)
    })
  }
  validateExp() {
    const { expYear, expError } = this.state
    if (!expError) {
      this.setState({
        expError: expYear.toString().length !== 2 && 'Incomplete exp date'
      })
    }
  }


  handleCvv(e) {
    const { cardNumber } = this.state
    const cvvCode = e.target.value
    if (cvvCode.length > 2 && (cardNumber?.startsWith('37') ? cvvCode.length > 4 : cvvCode.length > 3)) return
    this.setState({ cvvCode: e.target.value })
  }

  creditCardFormat(card, regex) {
    if (!card) return card
    if (card[0] === '3') {
      let str = card
      let str2
      if (str.length === 4) {
        str = str + ' '
        return str
      } else if (str.length === 10) {
        str = str + ''
        return str
      } else if (str.length === 11) {
        str = str + ' '
        return str
      }
      else {
        return str
      }
    }
    const cardWithoutSpace = card.replace(/\s/g, '')
    const cardTemp = cardWithoutSpace.match(/.{1,4}/g)
    return cardTemp.join(' ')
  }
  checkfee = () => {
    const { orgdata, amount, fee, storeFee, updateAmount } = this.props;
    storeFee(!fee)
    if (!this.props.fee) {
      let discount = orgdata?.[0]?.enabledIntegrations?.deenfund?.platformFeeDiscount ? orgdata[0].enabledIntegrations.deenfund.platformFeeDiscount : 0;
      const dfFee = discount ? 0.0175 * (discount / 100) : 0.0175
      const totalAmount = amount >= 5 ? (!fee ? (amount + 0.3 + (amount * dfFee)) / 0.971 : amount) : 0;
      this.props.updateAmount(parseFloat(parseFloat(totalAmount).toFixed(2)))
      this.props.updateAmountWithoutFee(parseFloat(parseFloat(amount).toFixed(2)))
      this.props.updatetotalAmount(parseFloat(parseFloat(totalAmount - amount).toFixed(2)))
    }
    else {
      const total = this.props.total
      this.props.updateAmount(parseFloat(parseFloat(amount - total).toFixed(2)))
    }
  }
  render() {
    console.log("orgdata", this.props.orgdata )

    const {
      isProceed,
      cardNumber,
      cardHolderName,
      expMonth,
      expYear,
      cvvCode,
      zipCode,
      error,
      cvvErrorMsg,
      errorMsg,
      expDate,
      width,
      expError,
      cardError,
      loading,
      tooltipFlag,
      check,
      invalidCard
    } = this.state
    const {
      verified, payment: { category, donationType: frequency, categoryId, fee, amount },
      userInfo: { firstName, lastName, email, phoneNo, orgdata }
    } = this.props
    console.log(this.props, 'props')
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'cente',
            flexDirection: 'column'
          }}
        >
          <div style={{ width: '600px', alignSelf: 'center', marginTop: '15px', }}>
            {isProceed ? (
              <>
                <Grid container spacing={0}>
                  <Grid item sm={12} lg={12}>
                    <div className='totalCharge'>
                      <div className='leftCharge'>
                        <div className='chargeparagraph'>Total Charge</div>
                        <div>
                          <span className='price'>${(this.props.amount ? this.props.amount?.toFixed(2) : 0)}</span>
                          <span className='edit' onClick={() => {
                            this.props.updateEDIT(true)
                            this.props.setSteps(1)
                            this.props?.fee && this.props.storeFee(!this.props?.fee)
                            this.props.updateAmount(parseFloat(parseFloat(this.props?.amount - this.props?.total).toFixed(2)))

                          }}>EDIT</span>
                        </div>
                      </div>
                      {orgdata?.[0]?.taxIdStatus?.taxExempt && <div className='rightChargeBox'>
                        <div className='check-box'>
                          <span className='checkIcon'>
                            <CheckCircleIcon style={{ color: '#05CE78' }} /> Tax-deductable
                    </span>
                        </div>
                      </div>}
                    </div>
                  </Grid>

                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={7} lg={7}>
                    <Input
                      value={cardHolderName}
                      label='Card Holder Name'
                      type='text'
                      onChange={(e) => {
                        const nameRegx = /^[a-zA-Z\s\.]*$/
                        if (e.target.value.length <= 30 && e.target.value.match(nameRegx)) {
                          this.setState({ cardHolderName: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })
                        }
                      }}
                      helperText={!error ? 'Full name as shown on credit card' : ''}
                      errorText={(error && !cardHolderName && 'Name on card is required') || ''}
                    />
                  </Grid>
                  <Grid item sm={5} lg={5}>
                    <Input
                      placeholder='94017'
                      label='Zip/Postal Code'
                      type='text'
                      value={zipCode}
                      onChange={(e) => {
                        // const numRegx = /^[0-9]*$/
                        if (e.target.value.length <= 10) {
                          this.setState({ zipCode: e.target.value.toUpperCase() })
                        }
                      }}
                      helperText={!error ? 'From your billing address' : ''}
                      errorText={(error && !zipCode && 'Zip/postal code is required') || ''}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={12} lg={12}>
                    <Input
                      placeholder='4242 4242 4242 4242'
                      icon={cardNumber[0] === '4' && <img className="field_card" style={{ width: '', height: '' }} src={require('../../assets/rawimg/visa-icon.PNG')} alt="card-icon" /> 
                      || cardNumber[0] === '2' && <img className="field_card" style={{ width: '', height: '' }} src={require('../../assets/rawimg/mastercard2.png')} alt="card-icon" /> 
                      || (cardNumber?.startsWith('51') || cardNumber?.startsWith('52') || cardNumber?.startsWith('53') || cardNumber?.startsWith('54') || cardNumber?.startsWith('55')) && <img style={{ width: '34px', height: '30px', border: '1px solid #000066', backgroundColor: '#000066' }} src={require('../../assets/rawimg/mastercard-icon.png')} alt="card-icon" />
                      || (cardNumber?.startsWith('37') || cardNumber?.startsWith('34')) && <img className="field_card" style={{ width: '', height: '' }} src={require('../../assets/rawimg/american-express.png')} alt="card-icon" /> 
                      || cardNumber?.startsWith('60') && <img style={{ width: '34px', height: '30px' }} src={require('../../assets/rawimg/discover.png')} alt="card-icon" /> 
                      || (cardNumber?.startsWith('2131') || cardNumber?.startsWith('1800') || cardNumber?.startsWith('35')) && <img style={{ width: '34px', height: '30px' }} src={require('../../assets/rawimg/jcb-card.png')} alt="card-icon" /> 
                      || cardNumber?.startsWith('62') && <img style={{ width: '36px', height: '28px' }} src={require('../../assets/rawimg/unionpay.png')} alt="card-icon" /> 
                      || (cardNumber?.startsWith('30') || cardNumber?.startsWith('36')) && <img style={{ width: '36px', height: '32px' }} src={require('../../assets/rawimg/diners-club.png')} alt="card-icon" /> 
                      }
                      placeholder='4242 4242 4242 4242'
                      value={cardNumber}
                      label='Card Number'
                      onBlur={() => {
                        if (cardNumber.length !== 0) {
                          const isError = cardNumber.length < 15
                          this.setState({ cardError: isError })
                        }
                      }}
                      inputMode='numeric'
                      helperText={!errorMsg && !cardError && !error ? 'Enter valid card number' : ''}
                      errorText={errorMsg === 'restricted_card' ? 'The card is restricted' : errorMsg === 'expired_card' ? 'The card has expired' : (errorMsg === 'incorrect_number' || cardError) ? 'Enter valid card number' : ((error && !cardNumber && 'Enter valid card number') || (invalidCard && 'Enter valid card number'))}
                      onChange={(e) => {
                        if (e.target.value.replace(/\s/g, '').match(/^\d+$/)) {
                          const card = this.creditCardFormat(e.target.value)
                          const cardWithoutSpace = card.replace(/\s/g, '')
                          this.setState({
                            cardNumber: cardWithoutSpace.length > 16 ? cardNumber : card,
                            cvvCode: !e.target.value?.startsWith('37') && cvvCode.length >= 4 ? cvvCode.slice(0, 3) : cvvCode
                          })
                        } else if (!e.target.value) {
                          this.setState({
                            cardNumber: ''
                          })
                        }
                      }}

                      onKeyDown={(e) => {
                        if (cardNumber[0] === '3') {
                          if (e.keyCode === 8) {
                            if (cardNumber.length === 12) {
                              const newCardNumber = cardNumber.substring(0, 11)
                              this.setState({ cardNumber: newCardNumber })
                            } else if (cardNumber.length === 5) {
                              const newCardNumber = cardNumber.substring(0, 4)
                              this.setState({ cardNumber: newCardNumber })
                            }
                          }
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={6}>
                    <Input
                      placeholder='MM / YY'
                      value={expDate}
                      onChange={(e) => {
                        this.handleExpDate(e)
                      }}
                      onBlur={() => this.validateExp()}
                      label='Exp Date'
                      type='text'
                      inputMode='numeric'
                      errorText={errorMsg === 'invalid_expiry_month' ? 'Invalid Expiry Month' : errorMsg === 'invalid_expiry_year' ? 'Invalid expiry Year' : expError || (!expYear && error ? 'Invalid expiry date' : '')}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <Input
                      onChange={(e) => {
                        const numRegx = /^[0-9]*$/
                        if (e.target.value.match(numRegx)) {
                          this.handleCvv(e)
                        }
                      }}
                      placeholder='CVC Code'
                      value={cvvCode}
                      label='CVC Code'
                      inputMode='numeric'
                      icon={<InputAdornment>
                        <ClickAwayListener onClickAway={() => this.setState({ tooltipFlag: false })}>
                          <Tooltip open={tooltipFlag} title="Your card's CVC is a 3 or 4 digit number, usually on the back next to your signature" placement='top-end'>
                            <img
                              onClick={() => this.setState({ tooltipFlag: true })}
                              onMouseEnter={() => this.setState({ tooltipFlag: true })}
                              onMouseOut={() => this.setState({ tooltipFlag: false })}
                              src={require('../../assets/rawimg/help-24px.svg')} style={{ cursor: 'pointer' }}
                              alt="flag"
                            />
                          </Tooltip>
                        </ClickAwayListener>
                      </InputAdornment>}
                      errorText={
                        // (errorMsg === 'incorrect_cvc' && 'Incorrect CVC') || (errorMsg === 'invalid_cvc' && 'Invalid CVC code') || (error && !cvvCode && 'Invalid CVC code') || (error && cardNumber[0] === '3' && cvvCode.length < 4 && 'Invalid CVC code') || (error && cardNumber[0] !== '3' && cvvCode.length < 3 && 'Enter valid CVC code') || (error && cvvErrorMsg)
                      !cvvCode && error && 'CVV code is required' || (errorMsg === 'incorrect_cvc' && 'Incorrect CVV') || (errorMsg === 'invalid_cvc' && 'Invalid CVV code') || (error && !cvvCode && 'Invalid CVV code') || (error && cardNumber?.startsWith('37') && cvvCode.length < 4 && 'Invalid CVV code') || (error && !cardNumber?.startsWith('37') && cvvCode.length < 3 && 'Enter valid CVV code') || (error && cvvErrorMsg)
                    }
                    />
                  </Grid>
                </Grid>
                {orgdata?.[0]?.taxIdStatus?.taxExempt && <div className='btns'>
                  <Checkbox
                    checked={fee}
                    onClick={() => this.checkfee()}
                  />
                  <div className='check-box'>
                    <span onClick={() => this.checkfee()}>
                      Add processing fees so that 100% of my donation goes directly to cause.
                    </span>
                  </div>
                </div>}
                {!loading ? <Button
                  // disabled={!isValid}
                  onClick={() => this.stripTokenApiHit()}
                  title='Submit Donation'
                  widthHeight={[100, 70]}
                  customColor={'#05CE78'}
                  icon={<LockIcon />}
                  className='checkout'
                /> : <div style={{ marginTop: 30, marginBottom: 30, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color='primary' />
                  </div>}
                <center>
                  <p className="donation_pv">
                    By donating you agree our <span onClick={() => window.open("https://deenfund.com/terms-policies")}>Terms & Conditions</span> and{' '}
                    <span onClick={() => window.open("https://deenfund.com/privacy-policy")}>Privacy policy</span>
                  </p>
                </center>
              </>
            ) : (
                <>
                  <Input
                    label=' Street Address '
                    type='text'
                    helperText='Helper text'
                  />
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Input
                      label=' City/Town'
                      type='text'
                      helperText='Helper Text'
                      style={{ width: '120%' }}
                    />
                    <Input
                      label='State/Province'
                      type='text'
                      helperText='Helper text'
                      style={{ width: '95%' }}
                    />
                  </div>
                  <Input
                    label=' Zip Code '
                    type='text'
                    helperText='Helper text'
                  />
                  <center>
                    <p className="donation_pv">
                      By donating you agree our <span>Terms & Conditions</span> and{' '}
                      <span>Privacy policy</span>
                    </p>
                  </center>
                  {!loading ? <Button
                    onClick={() => this.stripTokenApiHit()}
                    title='Submit Donation'
                    widthHeight={!width ? [510, 60] : [470, 60]}
                    customColor={'#05CE78'}
                    icon={<LockIcon />}
                    className='checkout'
                  /> : <CircularProgress color='primary' />}
                </>
              )}
          </div>
        </div>
      </>
    )
  }
}
function mapStateToProps(state) {
  return {
    userInfo: state.users,
    user: state.users && state.users.userAsGuest,
    phoneNo: state.users.phoneNo,
    payment: state.payment,
    editFlag: state.steps,
    errorFlag: state.users.errorFlag,
    fee: state.payment.fee,
    amount: state.payment.amount,
    edit: state.payment.edit,
    orgdata: state.users.orgdata,
    total: state.payment.total,
    orgId : state.users.orgId,
    amountwithoutfee: state.payment.amountwithoutfee
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateErrorFlag: (errorFlag) => dispatch(updateErrorFlag(errorFlag)),
    storeFee: (payload) => dispatch(updateٖٖٖٖٖٖٖFee(payload)),
    isHideButton: (btnBoolen) => dispatch(isHideButton(btnBoolen)),
    updateEDIT: (btnBoolen) => dispatch(updateEDIT(btnBoolen)),
    setSteps: (val) => dispatch(setSteps(val)),
    isHomepage: (payload) => dispatch(isHomepage(payload)),
    updateAmount: (payload) => dispatch(updateAmount(payload)),
    updateAmountWithoutFee: (payload) => dispatch(updateAmountWithoutFee(payload)),
    updatetotalAmount: (payload) => dispatch(updatetotalAmount(payload)),
    clearUserFields: () => dispatch(clearUserFields()),
    updateStripeAmount: (payload) => dispatch(updateStripeAmount(payload)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Payment)