import React from 'react'


export function markdowntoreact(el) {
    return el.map(e => {
      switch (e.type) {
          case "block-quote":
            return <blockquote>{e.children.map(em => {
               if (e.hasOwnProperty('italic')) {
               return <em>{em.text}</em>
               }
               if (e.hasOwnProperty('bold')) {
                  return <strong>{em.text}</strong>
                  }
                  else{
                      return em.text
                  }
           })}</blockquote>
          case "paragraph":
           return <span>{e.children.map(em => {
               if (e.hasOwnProperty('italic')) {
               return <em>{em.text}</em>
               }
               if (e.hasOwnProperty('bold')) {
                  return <strong>{em.text}</strong>
                  }
                  else{
                      return em.text
                  }
           })}</span>;
          case "heading-one":
            return <h1>{e.children.map(em => {
              if (e.hasOwnProperty('italic')) {
              return <em>{em.text}</em>
              }
              if (e.hasOwnProperty('bold')) {
                 return <strong>{em.text}</strong>
                 }
                 else{
                     return em.text
                 }
          })}</h1>;
          case "heading-two":
            return <h2>{e.children.map(em => {
              if (e.hasOwnProperty('italic')) {
              return <em>{em.text}</em>
              }
              if (e.hasOwnProperty('bold')) {
                 return <strong>{em.text}</strong>
                 }
                 else{
                     return em.text
                 }
          })}</h2>;
          default:
            return null
        }
    });
}