import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/dist/js/bootstrap.bundle'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import {  HomePageCards } from '../../components'
import '../Home/style.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string';
import { updateuserToken, orgId, homeScreen,addHomeScreen } from '../../store/users/actions'
import {
  getHomeScreenPreviewById,
} from "../../components/Donation/apis/index";

class HomePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annoucements: [],
      boolean: true
    }
  }

  getHomeScreenData = async (id) => {
      const res = await getHomeScreenPreviewById(id)
      this.props.setHomeScreen(res?.data?.body?.data ?? {})
  }

  componentDidMount() {
    this.slider();

    let search = this?.props?.location?.search;
    const parsed = queryString.parse(search);

    if(parsed?.orgId && parsed?.homeScreenId) {
      this.props.updateuserToken("homeScreenPreview")
      this.props.orgId(parsed.orgId)
      this.props.addHomeScreen(parsed.homeScreenId)
      this.getHomeScreenData(parsed.homeScreenId)
    }

  }
  slider = () => {
    $('#myCarousel').carousel({
      interval: 6000,
      pause: 'false'
    })
  }

  componentDidUpdate(prevProps) {

    if(this.props.homeScreen?.background?.backgroundType === "image") {
      document.body.style.backgroundImage = `url('${this.props.homeScreen?.background?.imageUrl}')`;
    } else {
      document.body.style.backgroundImage = `url('')`;
      document.body.style.backgroundColor = `${this.props.homeScreen?.background?.themeColor}`;
    }
    if (prevProps.annoucements?.length !== this.props.annoucements?.length) {
      this.setState({ boolean: false }, () => {
        this.setState({ boolean: true })
      })
    }
  }

  render() {
    const { homeScreen } = this.props;
    const leftBtn = homeScreen?.layout?.leftButtonCard ?? null
    const middleBtn = homeScreen?.layout?.middleButtonCard ?? null
    const rightBtn = homeScreen?.layout?.rightButtonCard ?? null
    const templateName = homeScreen?.template?.name?.toLowerCase() ?? "template one";

    return (
      <div className='main'>

        <div className="KioskBody">
          <div className='kiosk_container' style={{"pointerEvents": "none" }}>

            <Grid container spacing={3}>
              
              <Grid item xs={12}>
                <HomePageCards prevProps={this.props} />
              </Grid>

              {templateName !== "template five" && <Grid item xs={12}>

              <Grid container spacing={3}>	      

              <Grid item xs={4}> 
                {
                  this.props.homeScreen?.layout?.leftButtonCard && <div className='evnt_link_sec'>
                      <Link to={"#"} style={{ width: '100%', backgroundColor: leftBtn.backgroundColor ?? "", color: leftBtn.color ?? "" }} className='about'>{leftBtn.label ?? ""}</Link>
                  </div>
                }
              </Grid>

              <Grid item xs={4}> 
                {
                  middleBtn && <div className='evnt_link_sec'>
                      <Link to={"#"} style={{ width: '100%', backgroundColor: middleBtn.backgroundColor ?? "", color: middleBtn.color ?? "" }} className='about'>{middleBtn.label ?? ""}</Link>
                  </div>
                }
              </Grid>

              <Grid item xs={4}> 
                {
                  rightBtn && <div className='evnt_link_sec'>
                      <Link to={"#"} style={{ width: '100%', backgroundColor: rightBtn.backgroundColor ?? "", color: rightBtn.color ?? "" }} className='about'>{rightBtn.label ?? ""}</Link>
                  </div>
                }
              </Grid>
              </Grid>
              </Grid>}

            </Grid>

            {/* <div className='announcement_section'>
                  <img className='anc_icon' src={require('../../assets/rawimg/annoucement_icon.png')} />
                  <div id='demo' className='carousel slide position-relative announcement_slider' data-ride='carousel'>

                  {boolean && <div className='carousel-inner'>
                      {annoucements && annoucements.length > 0 && annoucements.map((item, index) => <div key={index} className={`carousel-item ${index === 0 ? 'active' : ""} `}>
                        <h1>{item.title.slice(0, 34)}</h1>
                      </div>
                      )}

                    </div>}

                    <div className='slider_arrow'>
                      <a className='prv_arrow' href='#demo' data-slide='prev'><i className='fas fa-chevron-left' /></a> <a className='next_arrow' href='#demo' data-slide='next'><i className='fas fa-chevron-left' /></a>
                    </div>
                  </div>
                </div> */}

          </div>
        </div>



      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    orgId: state.users.orgId,
    token: state.users.token,
    homeScreen: state.users.homeScreen,
    annoucements: state.users.annoucements
  }
}

function mapDispatchtoProps(dispatch) {
  return {
      updateuserToken: (token) => dispatch(updateuserToken(token)),
      orgId: (id) => dispatch(orgId(id)),
      addHomeScreen: (id) => dispatch(addHomeScreen(id)),
      setHomeScreen: (orgdata) => dispatch(homeScreen(orgdata)),
  }
}

export default connect(mapStateToProps, mapDispatchtoProps)(withRouter(HomePreview))