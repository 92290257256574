import React from 'react'
import { Link } from 'react-router-dom'

import './style.css'

function Cards ({ thumbnailImage, title , id ,description , image}) {
  return (

     
    <div className='services_col'>
      <span className='services_alfa' />
      <img src={thumbnailImage} />

      <h2>{title}</h2>
      <Link className='services_more_details' 
      to={{
        pathname: '/services-details',
        state: {
          services_id: id,
          description:description,
          image : image,
          title : title
        }
      }}
     >
        Learn more <i className='fas fa-chevron-right' /></Link>

    </div>

  )
}

export default Cards
