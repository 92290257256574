const donationCategory = [
  {
    key: '',
    value: ['Generaal Donation', 'Zakat']
  },
  {
    key: '',
    value: ['Ramadan Iftar', 'Sadaqah']
  },
  {
    key: '',
    value: ['Fidya', 'New construction for new school']
  },
  {
    key: '',
    value: ['Kaffara', 'Weekend Class']
  }
]

const donationAmount = [
  {
    value: [25, 50, 100]
  },
  {
    value: [200, 250, 300]
  }
]

export { donationCategory, donationAmount }
