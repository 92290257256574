import React from 'react';
import moment from 'moment';

class Countdown extends React.Component {
    state = {
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            const { timeTillDate, timeFormat } = this.props;
            var then = new Date(timeTillDate)
            var now =  new Date()


            var diff = moment.duration(moment(then).diff(moment(now)));
            const days = diff.days();
            const hours = diff.hours();
            const minutes = diff.minutes();

            this.setState({ days, hours, minutes });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { days, hours, minutes } = this.state;

        // Mapping the date values to radius values
        // const daysRadius = mapNumber(days, 30, 0, 0, 360);
        // const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
        // const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
        // // const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);



        return (

            <div className='ev_dt_time'>
                {days && days > 0 ? (
                    <li>{days < 10 ? '0' + days : days}<span>Days</span></li>

                ) : <li>0<span>DAYS</span></li>}
                <li><label>:</label></li>

                {hours && hours > 0 ? (
                    <li>{hours < 10 ? '0' + hours : hours}<span>hours</span></li>) :
                    <li>{0}<span>HOURS</span></li>}

                <li><label>:</label></li>


                {minutes && minutes > 0 ? (
                    <li>{minutes < 10 ? '0' + minutes : minutes}<span>minutes</span></li>
                ) :
                    <li>{0}<span>MINS</span></li>
                }
                {/* {seconds && (
                        <div className="countdown-item">
                            {seconds}
                            <span>seconds</span>
                        </div>
                    )} */}
            </div>

        )
    }
}


// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle




// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers

// function mapNumber(number, in_min, in_max, out_min, out_max) {
//     return (
//         ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
//     );
// }

export default Countdown