import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateAmount, updateDonationType, updateٖٖٖٖٖٖٖFee, categoryDonation, updateEDIT, addNote, isHomepage, saveCategories, setSteps, updateisAmount , updateStripeAmount } from '../../store/payment/actions'
import LockIcon from '@material-ui/icons/Lock'
import InfoIcon from '@material-ui/icons/Info'
import { Button, OutlinedInput } from '../../components'
import { TextField } from '@material-ui/core'
import { donationCategory, donationAmount } from '../../json'
import './css/Donation.css'
import CancelIcon from '@material-ui/icons/Cancel'
import { getCategories, getSelectedDonationCategories } from './apis'
class Donation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'I Want To Donate',
      donationErrorText: 'Enter min of $5',
      amountInput: '',
      amountActive: '',
      omBtnAvtive: '',
      addNote: false,
      note: '',
      defaultValueCat: '',
      defaultKeyCat: '',
      categories: [],
      inputAmount: '',
      errorFlag: false,
      isDonationError: false,
    }

    this.nextPage = this.nextPage.bind(this)
    this.jumpToStep = this.jumpToStep.bind(this)
    this.addNote = this.addNote.bind(this)
    this.donationErrorhandle = this.donationErrorhandle.bind(this)
  }

  componentDidMount() {
    if (this.props.page === 'home') {
      this.props.isHomepage(true)
      this.setState({ title: 'I Want To Donate' })
    }
    else if (this.props.location.pathname === '/donation') {
      this.setState({ title: '' })
    }
    if (this.props.location.state) {
      const isHomepage = this.props.location.state.ishomepage
      if (isHomepage) {
        this.props.isHomepage(false)
        this.setState({ title: 'Select Donation Category' })
      } else {
        this.props.isHomepage(true)
      }
      if (this.props.edit) {
        this.props.isHomepage(true)
      }
    }

    this.setState({ amountActive: this.props.amount, omBtnAvtive: this.props.donationType })
    this.getCategories()

    const { embedFormData, storeAmount, updateStripeAmount, updateisAmount } = this.props;
    const defaultAmount = embedFormData?.preselectAmounts?.find((el) => el.setDefault);
    // console.log("defaultAmount", defaultAmount)
    if(defaultAmount?.amount) {
      storeAmount(defaultAmount.amount)
      updateStripeAmount(parseInt(defaultAmount.amount))

      updateisAmount(false)
      this.setState({ amountActive: defaultAmount.amount, amountInput: false, isDonationError: false, inputAmount: '' })

    }
  }
  async getCategories() {
    const orgId = this.props.orgId
    const { storeCategory, saveCategories } = this.props
    let categories = []
    // await this.props.forNextStep('categories', categories)
    if (orgId) {
      // const categoriesRes = await getCategories(orgId)
      const categoriesRes = await getSelectedDonationCategories(orgId)

      const list = categoriesRes.data.body.data
      console.log("list", list)
      const list2 = list.filter(category => category.status !== "Inactive")
      if (list2 && list2.length) {
        list2.map(item => {
          const categoryItem = categories[categories.length - 1]
          if (categoryItem && categoryItem.value.length < 2) {
            categoryItem.value.push(item.name)
            categoryItem.key.push(item._id)
          } else {
            categories.push({ value: [item.name], key: [item._id] })
          }
        })
      } else {
        categories = donationCategory
      }
    } else {
      categories = donationCategory
    }
    this.setState({
      categories,
      loading: false,
      defaultValueCat: categories[0].value[0],
      defaultKeyCat: categories[0].key[0]
    },
      () => {
        saveCategories(categories)
        storeCategory(categories[1] ? categories[1].value[0] : categories[0].value[0],
          categories[1] ? categories[1].key[0] : categories[0].key[0])

      })
    // //('cat->',categories[0].key[0], categories[0].value[0])
  }

  // handleAmountValue = value => {
  //   const { amount } = this.state;
  //   this.setState({ amount: value });
  // };
  addNote(e) {
    if (e.target.value.length > 50) {
      return false
    } else {
      this.setState({
        note: e.target.value,
        length: e.target.value.length
      })

      this.props.addNote(e.target.value)
    }
  }

  donationErrorhandle(value) {
    const { storeAmount, amount, updateisAmount, updateStripeAmount, embedFormData } = this.props
    // console.log(embedFormData, "embedFormData")
    this.setState({ inputAmount: value })
    if (value >= (embedFormData.donationMinimumAmount ? parseInt(embedFormData.donationMinimumAmount) : 1)) {
      this.setState({ isDonationError: false, amountActive: '', donationErrorText: `Enter min of $${embedFormData?.donationMinimumAmount}`  })
      storeAmount(parseInt(value))
      updateStripeAmount(parseInt(value))

    } else {
      this.setState({ isDonationError: true, amountActive: '', donationErrorText: `Enter min of $${embedFormData?.donationMinimumAmount ? "$" + parseInt(embedFormData.donationMinimumAmount) : "$1"}` })
      storeAmount('')
    }
    this.setState({ inputAmount: parseInt(value) })
    updateisAmount(true)
  }

  nextPage() {
    if (this.state.isDonationError === false) {
      this.props.isHomepage(false)
      this.setState({ title: 'Select Donation Category' })
      this.props.homeNav.history.push('/donation', { ishomepage: true })
    }


  }

  jumpToStep() {
    this.props.setSteps(3)
    this.props.updateEDIT(false)
  }

  componentDidUpdate(prevProps, prevState) {
    const { omBtnAvtive, check } = this.state
    const { storeDonationType, donationType } = this.props
    storeDonationType(donationType)

  }


  render() {
    const { isProceed, title, amountInput, amountActive, omBtnAvtive, addNote, note, inputAmount, isDonationError, donationErrorText, errorFlag } = this.state
    const { amount, storeAmount, ishome, storeCategory, categories, isamount, updateisAmount , updateStripeAmount } = this.props;

    


    const btnStyle = {
      fontSize: '40px',
      color: '#222831',
      fontFamily: 'Roboto-Regular'
    }
    const btnActive = {
      fontSize: '40px',
      color: '#fff',
      backgroundColor: '#05CE78',
      fontFamily: 'Roboto-Regular'
    }
    const donationCatStyle = {
      fontSize: '20px',
      color: '#000',
      fontFamily: 'Roboto-Regular',
      width: 290,
    }
    const donationCatStyleactive = {
      fontSize: '20px',
      color: '#fff',
      fontFamily: 'Roboto-Regular',
      background: '#05CE78',
      width: 290,
    }
    const onTimeMontlybtn = {
      normal: {
        borderRadius: '0px',
        margin: '0px',
        fontSize: '22px',
        fontFamily: 'Roboto-Medium',
        textTransform: 'capitalize',
        letterSpacing: '1.26px',
        textTransform: 'uppercase',
        color: '#1A1A1A'

      },
      active: {
        borderRadius: '0px',
        margin: '0px',
        fontSize: '22px',
        fontFamily: 'Roboto-Medium',
        textTransform: 'capitalize',
        background: '#05CE78',
        color: '#fff',
        letterSpacing: '1.26px',
        textTransform: 'uppercase'

      }
    }


    return (
      <>
        <div>
          <center>

            <div className='right_col_title'>{title}</div>
            {ishome && (
              <>
                <div className='categoriesCont'>
                  {donationAmount.map((items, index) => {
                    return (
                      <div key={index + '-donation'} className='btnRow'>
                        {items.value.map((itemValues, index) => {
                          return (
                            <Button
                              title={'$' + itemValues}
                              // onClick={() => this.handleAmountValue(itemValues)}
                              onClick={() => {
                                storeAmount(itemValues)
                                updateStripeAmount(parseInt(itemValues))

                                updateisAmount(false)
                                this.setState({ amountActive: itemValues, amountInput: false, isDonationError: false, inputAmount: '' })
                              }}
                              widthHeight={[163, 100]}
                              style={!amountInput && amountActive === itemValues || !amountInput && amount === itemValues ? btnActive : btnStyle}
                              variant='outlined'
                              key={Math.random() * 2}

                            />
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <OutlinedInput className="amount_input"
                  value={isamount ? inputAmount ? inputAmount : amount : ''}
                  leftText='$'
                  style={isDonationError ? { border: '1px solid red' } : {}}
                  placeholder='Enter Amount'
                  customBorder={isDonationError && '2px solid red'}
                  onClick={() =>
                    this.setState({ amountActive: false }
                      , () => {
                        storeAmount('')
                      })}
                  onChange={(e) => {
                    if ((e.target.value).indexOf('.') === -1 && e.target.value.length <= 6 && e.target.value <= 899999) {
                      // this.setState({
                      //   otherAmountValue: e.target.value
                      // })
                      this.donationErrorhandle(e.target.value)
                    }
                  }}
                  errorText={(isDonationError && donationErrorText)}

                />

                <div className='om-btn'>
                  <Button
                    title='ONE TIME'
                    widthHeight={[267, 70]}
                    variant='outlined'
                    style={this.props.donationType === 'One Time' ? onTimeMontlybtn.active : onTimeMontlybtn.normal}
                    onClick={() => {
                      this.props.storeDonationType('One Time')
                    }
                    }
                    icon={<i className='fas fa-hand-holding-heart' />}
                  />
                  <Button
                    style={this.props.donationType === 'Monthly' ? onTimeMontlybtn.active : onTimeMontlybtn.normal}
                    title='MONTHLY'

                    widthHeight={[267, 70]}
                    variant='outlined'
                    onClick={() => {
                      this.props.storeDonationType('Monthly')
                    }
                    }

                    icon={<i className='fas fa-sync' />}

                  />
                </div>
                {/* <div className='note-div'>
                  {!addNote ? <a className='note' onClick={() => this.setState({ addNote: true })}>Add Note (Optional)</a>
                    : <div className='input-field'>
                      <div className='note-input not_field_label messsage_field'>

                        <TextField
                          style={{ width: '100%', display: 'flex', }}
                          variant='outlined'
                          placeholder='Write your message here'
                          onChange={(e) => {
                            this.addNote(e)
                          }}
                          value={note}
                          type='text'
                        />
                        <p className='char'>{`CHAR ${note.length}/50`}</p>
                      </div>
                      <div
                        className='cancel' onClick={() => {
                          this.setState({ addNote: false, note: '' })
                          this.props.addNote('')
                        }}
                      >
                        <CancelIcon />
                      </div>

                    </div>}
                </div> */}

              </>
            )}
            {!ishome && <div className='categoriesCont'>
              {!ishome && categories.map((items, index) => {
                return (
                  <div key={items.key[0] + '-donation'} className='btnRow'>
                    <Button
                      title={items.value[0]}
                      widthHeight={[290, 100]}
                      // style={index === 1 ? donationCatStyleactive : donationCatStyle}
                      style={donationCatStyle}
                      variant='outlined'
                      onClick={() => {
                        this.props.onForNextStep()
                        storeCategory(items.value[0], items.key[0])
                      }}
                    />
                    {items.value[1] && <Button
                      title={items.value[1]}
                      widthHeight={[290, 100]}
                      style={donationCatStyle}
                      variant='outlined'
                      onClick={() => {
                        this.props.onForNextStep()
                        storeCategory(items.value[1], items.key[1])
                      }}
                    />}
                  </div>
                )

              })}
            </div>}

            {ishome && (
              <div className="home_proceed_btn">
                <Button
                  title='PROCEED NEXT'
                  onClick={() => {
                    this.props.page === 'home'
                      ? this.nextPage()
                      : this.props.edit === true ?
                        this.jumpToStep() :
                        // [this.props.setSteps(3),this.props.updateEDIT(false)]:
                        this.props.isHomepage(false)
                    this.props.location.pathname === '/donation' && this.setState({ title: 'Select Donation Category' })
                  }}
                  widthHeight={[100, 70]}
                  icon={<LockIcon />}
                />
              </div>
            )}
          </center>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    amount: state.payment.amount,
    note: state.payment.note,
    donationType: state.payment.donationType,
    fee: state.payment.fee,
    editFlag: state.steps,
    note: state.payment.note,
    ishome: state.payment.ishome,
    categories: state.payment.categories,
    edit: state.payment.edit,
    orgId: state.users.orgId,
    isamount: state.payment.isamount
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeAmount: (payload) => dispatch(updateAmount(payload)),
    storeDonationType: (payload) => dispatch(updateDonationType(payload)),
    isHomepage: (payload) => dispatch(isHomepage(payload)),
    storeCategory: (payload, categoryId) => dispatch(categoryDonation(payload, categoryId)),
    addNote: (payload) => dispatch(addNote(payload)),
    storeFee: (payload) => dispatch(updateٖٖٖٖٖٖٖFee(payload)),
    saveCategories: (payload) => dispatch(saveCategories(payload)),
    setSteps: (val) => dispatch(setSteps(val)),
    updateEDIT: (btnBoolen) => dispatch(updateEDIT(btnBoolen)),
    updateisAmount: (isamount) => dispatch(updateisAmount(isamount)),
    updateStripeAmount: (payload) => dispatch(updateStripeAmount(payload)),






    // storeAmount: (payload) => dispatch(updateAmount(payload)),
    // storeDonationType: (payload) => dispatch(updateDonationType(payload)),
    // storeCategory: (payload, categoryId) => dispatch(categoryDonation(payload, categoryId)),
    // editFeat: (payload) => dispatch(editFeat(payload)),
    // addNote: (payload) => dispatch(addNote(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Donation))
