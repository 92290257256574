import React, { Component } from 'react'
import DoneIcon from '@material-ui/icons/Done'
import './style.css'

export default class Stepper extends Component {
  constructor (props) {
    super(props)

    this.state = {
      Steps : ''
    }
    this.nextstep = this.nextstep.bind(this)
  }

  nextstep (nextProps) {
    const { Steps } = this.state
    this.setState({
      Steps: nextProps + 1
    })
  }

  componentWillReceiveProps (nextProps) {
    const Steps = nextProps.step;
    if(Steps === 1){
      this.setState({Steps : 0})
    }
    else {
      this.setState({Steps : Steps - 1})
    }

  }

  render () {
    const { Steps } = this.state
     const steps = this.props.step - 1

    return (
      <>
        <div className='stepwizard'>
          <div className='stepwizard-row setup-panel'>
            <div
              className={`${
                steps > 0
                  ? 'stepwizard-step step_done'
                  : 'stepwizard-step step_current'
              }`}
            >
              <a type='button' className=''>
                {steps >= 1 ? <DoneIcon /> : 1}
              </a>
              <p>Donation</p>
            </div>
            <div
              className={`${
                steps > 1
                  ? 'stepwizard-step step_done'
                  : steps === 1

                  ? 'stepwizard-step step_current'
                  : 'stepwizard-step'
              }`}
            >
              <a type='button' className='' disabled='disabled'>
                {steps >= 2 ? <DoneIcon /> : 2}
              </a>
              <p>Donor</p>
            </div>
            <div
              className={`${
                steps > 2
                  ? 'stepwizard-step step_done'
                  : steps === 2

                  ? 'stepwizard-step step_current'
                  : 'stepwizard-step'

              }`}
            >
              <a type='button' className='' disabled='disabled'>
                {steps >= 3 ? <DoneIcon /> : 3}
              </a>
              <p>Payment</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
