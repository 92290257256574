import React, { Component } from 'react'

import Logo from '../../assets/img/kiosk_logo.png';
import {  isDeviceVerified } from './Apis/index'
import './style.css'
import { updateuserToken, orgId } from '../../store/users/actions'
import { connect } from 'react-redux'

class onBoarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCodeExpired: false,
            otp: '',
            minutes: 5,
            seconds: 0,
            deviceId: ""
        }
    }
    componentDidMount() {
        this.checkStatus()
    }
    async checkStatus (){
        const deviceId = localStorage.getItem('deviceId')
        const checkVerification = await isDeviceVerified(deviceId)
        const checkStatus = checkVerification?.data?.body?.data?.device
        if(checkStatus.status === 'Live'){
          this.props.history.push("/home")
        }
        else {
           this.statusInterval = setInterval(async ()=> {
            const deviceId = localStorage.getItem('deviceId')
            const checkVerification = await isDeviceVerified(deviceId)
            const checkStatus = checkVerification?.data?.body?.data?.device
            if(checkStatus.status === 'Live'){
              this.props.history.push("/home")
            }
           },10000)
        }
      }
    componentWillUnmount() {
        clearInterval(this.statusInterval)
    }
    render() {
        return (
            <div className='support-bg'>
                <div className='on_boarding_main'>
                    <div className='login_message'>
                        <div>
                            <h1><img src={Logo} alt="Logo" /></h1>
                        </div>
                        <div>
                            <p className='link'><span style={{fontSize : 48}} className='headingText'>This Kiosk is currently paused/locked.</span></p>
                            <p className='headingText'>Please log into DC Console and reactivate your device.</p>
                        </div>
                    </div>
                    <div className='support'>
                        <div>
                            <p>Contact us at <span>support@deenkiosk.com</span> for technical support.</p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


function mapDispatchtoProps(dispatch) {
    return {
        updateuserToken: (token) => dispatch(updateuserToken(token)),
        orgId: (id) => dispatch(orgId(id)),
    }
}
export default connect(null, mapDispatchtoProps)(onBoarding)
