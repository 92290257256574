import React from "react";
import {
  salah,
  reminders,
  upcomingEvents,
  annoucements,
  services,
  organizations,getHomeScreenPreviewById
} from "../Donation/apis/index";
import { connect } from "react-redux";
import {
  updateSalah,
  updateReminders,
  updateEvents,
  updateAnnoucements,
  updateServices,
  updateuserToken,
  orgData,
  orgId,homeScreen
} from "../../store/users/actions";
import {  isDeviceVerified } from '../../screens/OnBoardingProcess/Apis/index'
import CircularProgress from "@material-ui/core/CircularProgress";

const withContext = (Component) => {
  class Context extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        salahResponse: [],
        dailyReminders: [],
        eventResponse: [],
        orgId: "",
        token: "",
        loader: "",
      };
    }
    async componentDidMount() {
      const orgId = this.props.orgId;
      const token = this.props.token;
      const homeScreenId = this.props.homeScreenId;
      if (orgId && token) {
        this.setState({ loader: true }, async () => {
          const getOrganization = async () => {
            try {
              const res = await organizations(orgId, token);
              this.props.orgData(res.data.body.data);
            } catch (e) {
              //("====>", e);
            }
          };
          const getHomeScreen = async () => {
            try {
              // if(homeScreenId) {
              //   const res = await getHomeScreenPreviewById(homeScreenId)
              //   this.props.homeScreen(res?.data?.body?.data ?? {})
              //   console.log("getHomeScreenPreviewById", res)
              // } else {
                const deviceId = localStorage.getItem("deviceId")
                const res = await isDeviceVerified(deviceId, "", "");
                let schedules = res?.data?.body?.data?.device?.schedules ?? [] 
                let scheduleScreen = schedules.find((el) => {
                    let d = new Date();
                    console.log("publishFrom", new Date(el.publishFrom), new Date(el.publishFrom) < d)
                    console.log("publishTo", new Date(el.publishTo), new Date(el.publishTo) > d)
                    if(new Date(el.publishFrom) < d && new Date(el.publishTo) > d) {
                      return el
                    }
                })
  
                if(scheduleScreen?.homeScreen?._id) {
                  this.props.homeScreen(scheduleScreen.homeScreen)
                } else {
                  this.props.homeScreen({})
                }
              // }

            } catch (e) {
              // ("====>", e);
            }
          };
          const getSalah = async () => {
            try {
              let salahRes = await salah(orgId, token);
              this.props.updateSalah(salahRes.data.body.data.reverse());
            } catch (e) {
              this.props.updateSalah([]);
            }
          };
          const getReminder = async () => {
            try {
              let remRes = await reminders(orgId, token);
              this.props.updateReminders(remRes.data.body.data);
            } catch (e) {
              this.props.updateReminders([]);
            }
          };

          const getEvents = async () => {
            try {
              let eventRes = await upcomingEvents(token, orgId);
              this.props.updateEvents(eventRes.data.body.data);
            } catch (e) {
              this.props.updateEvents([]);
            }
          };
          const getAnnouncements = async () => {
            try {
              let anncRes = await annoucements(orgId, token);
              this.props.updateAnnoucements(anncRes.data.body.data);
            } catch (e) {
              this.props.updateAnnoucements([]);
            }
          };
          const getServices = async () => {
            try {
              let servicesRes = await services(orgId, token);
              this.props.updateServices(servicesRes.data.body.data);
            } catch (e) {
              this.props.updateServices([]);
            }
          };

          const callDataInOrder = async () => {
            getSalah();
            getReminder();
            getAnnouncements();
            getEvents();
            getServices();
            getOrganization();
            getHomeScreen();
            this.setState({ loader: false });
          };

          await callDataInOrder();


        });
      }
    }

    componentWillUnmount() {
      this.setState({ loader: false });
    }
    render() {
      return (
        <>
          {this.state.loader ? (
            <CircularProgress color="primary" className="apiLoader" />
          ) : (
              <React.Fragment>
                <Component {...this.props} />
              </React.Fragment>
            )}
        </>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      orgId: state.users.orgId,
      token: state.users.token,
      homeScreenId: state.users.homeScreenId,
    };
  }
  const mapDispatchToProps = (dispatch) => {
    return {
      updateSalah: (salahdata) => dispatch(updateSalah(salahdata)),
      updateReminders: (remindersdata) =>
        dispatch(updateReminders(remindersdata)),
      updateEvents: (eventsdata) => dispatch(updateEvents(eventsdata)),
      updateAnnoucements: (annoucementdata) =>
        dispatch(updateAnnoucements(annoucementdata)),
      updateServices: (servicesdata) => dispatch(updateServices(servicesdata)),
      updateuserToken: (token) => dispatch(updateuserToken(token)),
      orgData: (orgdata) => dispatch(orgData(orgdata)),
      homeScreen: (orgdata) => dispatch(homeScreen(orgdata)),
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Context);
};

export default withContext;
