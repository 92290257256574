import React, { Component } from 'react'
import LockIcon from '@material-ui/icons/Lock'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import { updateFirstName, updateLastName, updateEmail, updateUserAsGuest, updateErrorFlag } from '../../store/users/actions'
import './css/Donation.css'


import {
  Button,
  Input,
  Verification,
  OutlinedInput,
  Signup
} from '../../components'

String.prototype.capitalize = function () {
  var sa = this.replace(/-/g, ' ')
  var saa = sa.toLowerCase()
  var sb = saa.replace(/(^|\s)([a-z])/g, function (m, p1, p2) { return p1 + p2.toUpperCase() })
  var sc = sb.replace(/\s+/g, ' ')
  return sc
}

class Donor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPhoneverify: false,
      eptype: {
        type: 'phone',
        title: 'Enter phone number',
        userContact: '',
        description: ''
      },
      issignUp: false,
      email: '',
      password: '',
      phoneNumber: '',
      num: '',
      error: false,
      emailError: false,
      emailErrorMessage: '',
      labelFlag: false,
      countryAlphaCode: '',
      checkError: false,
      isPhonenumberError: false,
      iscountryCode: '',
      dialCode: '1',
      countryFlag: '',
      isLastnameError: false,
      isFirstnameError: false,
      isemailError: false
    }

    this.handleisPhoneverify = this.handleisPhoneverify.bind(this)
    this.handleIsSignUp = this.handleIsSignUp.bind(this)
  }

  handleisPhoneverify(verifyObj) {
    this.setState({ isPhoneverify: true, eptype: verifyObj })
  }

  handleIsSignUp() {
    const { issignUp } = this.state
    this.setState({ issignUp: !issignUp })
  }
  checkValidation(firstName, lastName) {
    const { error, isemailError, phoneNumber , dialCode } = this.state
    if (this.props.firstName && this.props.lastName &&  this.props.phoneNo && this.props.phoneNo.length > dialCode.length + 1 && error === false && isemailError === false) {
      // this.setState({ firstName: '', lastName: '' , email : '' })
      this.props.onForNextStep()
    } else {
      this.setState({ error: true, phoneError: 'Invalid phone number', isPhonenumberError: true, isFirstnameError: true, isLastnameError: true, })
      if (this.props.firstName) {
        this.setState({ isFirstnameError: false, })
      }
      if (this.props.lastName) {
        this.setState({ isLastnameError: false, })
      }
      if (this.props.phoneNo.length > dialCode.length + 1 && error === false) {
        this.setState({ phoneError: '', isPhonenumberError: false, error: false })
      }
      if(!this.props.phoneNo || this.state.dialCode === this.props.phoneNo){
        this.setState({ phoneError: 'Phone number is required' , error: true ,  isPhonenumberError: true})

      }

    }




  }
  render() {
    const emailRegex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
    const {
      isPhoneverify,
      eptype,
      issignUp,
      email,
      phoneNumber,
      password,
      num,
      showAlert,
      firstName,
      lastName,
      error,
      emailError,
      emailErrorMessage,
      checkError,
      phoneError,
      labelFlag,
      countryAlphaCode,
      isLastnameError,
      isFirstnameError,
      isPhonenumberError

    } = this.state
    const { user, payment: { amount, category }, userInfo, errorFlag } = this.props



    // const btnStyle = { fontSize: '36px', color: '#000' }

    return (
      <>
        {!issignUp && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            {!isPhoneverify && (
              <>


                <div style={{ width: '100%', alignSelf: 'center' }}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Input

                        label='First Name'
                        type='text'
                        value={this.props.firstName}
                        onChange={(e) => {
                          const nameRegx = /^[a-zA-Z\s\.]*$/;
                          if ( e.target.value.length < 21 && e.target.value.match(nameRegx)) {
                            const spaceCount = e.target.value?.split(" ").length - 1;

                            if (spaceCount < 3) {
                              this.props.updateFirstName(e.target.value ? e.target.value[0].toUpperCase() + e.target.value.substring(1,21) : "");
                              this.setState({
                                firstName: e.target.value ? e.target.value[0].toUpperCase() + e.target.value.substring(1,21) : ""
                              });
                            }
                          }
                        }}
                        errorText={isFirstnameError && !firstName && 'First name is required'}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Input
                        label='Last Name'
                        type='text'
                        value={this.props.lastName}
                        onChange={(e) => {
                          const nameRegx = /^[a-zA-Z\s\.]*$/;
                          if (
                            e.target.value.length < 21 &&
                            e.target.value.match(nameRegx)
                          ) {
                            const spaceCount =
                              e.target.value.split(" ").length - 1;
                            if (spaceCount < 3) {
                              this.props.updateLastName(
                                e.target.value ? e.target.value[0].toUpperCase() + e.target.value.substring(1,21) : ""
                              );
                              this.setState({
                                lastName: e.target.value ? e.target.value[0]?.toUpperCase() + e.target.value?.substring(1,21) : "",
                              });
                            }
                          }
                        }}
                        // helperText={!error ? 'From your billing address' : ''}
                        errorText={isLastnameError && !lastName && 'Last name is required'}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item lg={12}>
                      <br />
                      <PhoneInput
                        inputProps={{
                          name: 'Phone Number',

                        }}
                        autocomplete="new-password"
                        onBlur={() => {
                          if (phoneNumber.length > this.state.dialCode.length + 1) {
                            if (countryAlphaCode === 'us') {
                              if (phoneNumber && phoneNumber.length !== 12) {
                                this.setState({ phoneError: 'Invalid phone number', error: true })
                              } else {
                                this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                              }
                            }

                            if (countryAlphaCode === 'ca') {
                              if (phoneNumber && phoneNumber.length !== 12) {
                                this.setState({ phoneError: 'Invalid phone number', error: true })
                              } else {
                                this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                              }
                            }

                            if (countryAlphaCode === 'my') {
                              if (phoneNumber && phoneNumber.length !== 13) {
                                this.setState({ phoneError: 'Invalid phone number', error: true })
                              } else {
                                this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                              }
                            }

                            if (countryAlphaCode === 'gb') {
                              if (phoneNumber && phoneNumber.length !== 13) {
                                this.setState({ phoneError: 'Invalid phone number', error: true })
                              } else {
                                this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                              }
                            }
                          }
                        }}

                        variant='outlined'
                        value={this.props.phoneNo}
                        onlyCountries={['us', 'ca', 'gb', 'my']}

                        country={'us'}
                        onKeyDown={e => this.setState({ num: e.target.value })}
                        onFocus={()=> {
                          this.setState({error : false , phoneError : '' , isPhonenumberError: false})
                        }}
                        onChange={(phoneNumber, data, e, formattedValue) => {
                          if (isPhonenumberError === true || error === true) {
                            if (data.countryCode === 'us') {
                              if (phoneNumber && phoneNumber.length !== 11) {
                                this.setState({ phoneError: 'Invalid phone number', error: true })
                              } else {
                                this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                              }
                            }

                            if (data.countryCode === 'ca') {
                              if (phoneNumber && phoneNumber.length !== 11) {
                                this.setState({ phoneError: 'Invalid phone number', error: true })
                              } else {
                                this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                              }
                            }

                            if (data.countryCode === 'my') {
                              if (phoneNumber && phoneNumber.length !== 12) {
                                this.setState({ phoneError: 'Invalid phone number', error: true })
                              } else {
                                this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                              }
                            }

                            if (data.countryCode === 'gb') {
                              if (phoneNumber && phoneNumber.length !== 12) {
                                this.setState({ phoneError: 'Invalid phone number', error: true })
                              } else {
                                this.setState({ phoneError: false, error: false, isPhonenumberError: false })
                              }
                            }
                          }


                          this.props.updateUserAsGuest({}, phoneNumber)
                          this.setState({ phoneNumber: `+${phoneNumber}`, countryAlphaCode: data.countryCode, dialCode: data.dialCode })
                          if (this.state.dialCode !== data.dialCode) {
                          this.props.updateUserAsGuest({}, data.dialCode)
                          this.setState({error : false , phoneError : '' , isPhonenumberError: false})


                          }

                        }}

                        containerStyle={{ width: '100%', height: '50px' }}
                        buttonStyle={{ width: '50px', height: '50px', borderRadius: '3px' }}
                        inputStyle={this.state.error ? {
                          marginLeft: '0px', height: '70px', width: '100%', fontFamily:
                            'Roboto-Regular', fontSize: '24px', border: '2px solid red'
                        } : { marginLeft: '0px', height: '70px', width: '100%', fontFamily: 'Roboto-Regular', fontSize: '24px' }}
                        containerClass={(this.state.error) && 'phoneErrorshow'}

                      />

                      {/* {!phoneError && isPhonenumberError &&
                        <span className="tel_error_msg"
                          style={{
                            display: 'flex',
                            fontFamily: 'Roboto-Regular',
                            letterSpacing: 0.4,
                            color: 'red !important',
                            fontSize: 16,
                            textIndent: '5px',
                            color: '#FF5353',
                            paddingTop: 0,
                            marginLeft: 18,
                            marginTop: 27,
                            position: "absolute"
                          }}
                        >Phone number is required
                                </span>} */}
                      {phoneError && <span className="tel_error_msg"
                        style={{
                          display: 'flex',
                          fontFamily: 'Roboto-Regular',
                          letterSpacing: 0.4,
                          color: 'red !important',
                          fontSize: 16,
                          textIndent: '5px',
                          color: '#FF5353',
                          paddingTop: 0,
                          marginLeft: 10,
                          marginTop: 24,
                          position: "absolute"
                        }}
                      >{phoneError}
                      </span>}
                    </Grid>
                  </Grid>

                  <Grid item lg={12}>
                    <br />
                    <Input

                      label='Email Address'
                      type='email'
                      value={this?.props?.email?.toLowerCase() || ''}
                      onChange={(e) => {
                        this.props.updateEmail(e.target.value)
                        this.setState({ email: e.target.value })
                        if (emailRegex.test(e.target.value)) {
                          this.setState({ email: e.target.value, emailError: false })
                        } else {
                          this.setState({ email: e.target.value, emailError: true, emailErrorMessage: '', })
                        }
                      }}
                      onBlur={() =>
                        emailError ?
                          !email ? this.setState({ emailErrorMessage: '', isemailError: false }) :
                            this.setState({ emailErrorMessage: 'Invalid email address', isemailError: true })
                          :
                          this.setState({ emailErrorMessage: '', isemailError: false })
                      }
                      // helperText='Enter email to receive confirmation (optional)'
                      helperText={emailErrorMessage === '' && 'Enter Email Address to receive confirmation (optional)' || !email && 'Enter Email Address to receive confirmation (optional)'}
                      errorText={(email && emailError && emailErrorMessage)}
                    />
                  </Grid>

                  <Grid item lg={12} className="proceed_btn">
                    <div style={{ marginTop: 63 }}>
                      <Button
                        title='PROCEED NEXT'
                        onClick={() => {
                          this.checkValidation(firstName, lastName)

                        }}
                        widthHeight={[100, 70]}
                        icon={<LockIcon />}
                      />
                    </div>

                  </Grid>



                </div>
              </>
            )}

          </div>
        )}

      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.users.user,
    userInfo: state.users,
    payment: state.payment,
    errorFlag: state.users.errorFlag,
    firstName: state.users.firstName,
    lastName: state.users.lastName,
    email: state.users.email,
    phoneNo: state.users.phoneNo
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateFirstName: (firstName) => dispatch(updateFirstName(firstName)),
    updateLastName: (lastName) => dispatch(updateLastName(lastName)),
    updateEmail: (email) => dispatch(updateEmail(email)),
    updateUserAsGuest: (otp, phoneNumber) => dispatch(updateUserAsGuest(otp, phoneNumber)),
    updateErrorFlag: (errorFlag) => dispatch(updateErrorFlag(errorFlag))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Donor)