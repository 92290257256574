import React from 'react'
import { Link } from 'react-router-dom'

import './style.css'

function Card (props) {
  const { title, showFooter, children, navigateTo } = props
  const className = title.toLowerCase() === 'upcoming events' ? 'event_card' : 'ev_card'
  return (
    <div className={className}>
      <h1>{title}</h1>
      {children}
      {
        showFooter &&
          <div className='view_all'>

            <Link to={`/${navigateTo}`} className='link'>View all <i className='fas fa-chevron-right icon' /> </Link>
          </div>
      }
    </div>
  )
}

export default Card
