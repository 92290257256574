import axios from 'axios'

// const sharedEnv = {
//   prod: process.env.REACT_APP_BASEURL_PROD_API,
//   dev: process.env.REACT_APP_BASEURL_DEV_API
// }
// let currentEnv
// if (process.env.REACT_APP_Project_Environment === 'localhost' || process.env.REACT_APP_Project_Environment === 'dev') {
//   currentEnv = 'dev'
// } else {
//   currentEnv = 'prod'
// }
// const orgBaseUrl = sharedEnv[currentEnv]

const envVariabels = {
  prod: {
    baseUrl: process.env.REACT_APP_BASEURL_PROD_API,
    paymentUrl: process.env.REACT_APP_PAYMENT_PROD_API
  },
  dev: {
    baseUrl:  process.env.REACT_APP_BASEURL_DEV_API,
    paymentUrl: process.env.REACT_APP_PAYMENT_DEV_API
  },
  local: {
    baseUrl: process.env.REACT_APP_BASEURL_API_LOCAL,
    paymentUrl: process.env.REACT_APP_PAYMENT_DEV_API
  }
}

const paymentBaseUrl = eval(`envVariabels.${process.env.REACT_APP_Project_Environment}.paymentUrl`);
const orgBaseUrl = eval(`envVariabels.${process.env.REACT_APP_Project_Environment}.baseUrl`);

export function getOTP(deviceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/digital-device-bridge/getOtpCode?deviceId=${deviceId}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject({})
        })
    })
  }


  export function isDeviceVerified(deviceId, ip = "", connected = "") {
    return new Promise((resolve,reject)=> {
      axios
          .get(`${orgBaseUrl}/digital-device-bridge/isDeviceVerified?deviceId=${deviceId}&ip=${ip}&connected=${connected}`)
          .then((res)=> {
            resolve(res)
          })
          .catch((error)=> {
            reject({error})
          })
    })
  }