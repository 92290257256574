import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import { markdowntoreact } from '../../util/markdown'

import Button from '../../components/Button'
import '../Services/style.css'
import './style.css'
import { services } from '../../components/Donation/apis'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
function ServicesDetails(props) {
  const [data, setdata] = useState(0);


  // const [description, setdescription] = useState(0);
  // const [image, setdescription] = useState(0);
  // const [description, setdescription] = useState(0);

  useEffect(() => {
    if (props.location.state) {
      const description = markdowntoreact(JSON.parse(props.location.state.description));
      const image = props.location.state.image;
      const title = props.location.state.title;
      const object = {
        description,
        image,
        title
      }
      setdata(object)
    }

  } , []);

  return (

    <div className='bg'>
      <div className='kiosk_container'>
    
          <div>
            <Grid container spacing={0}>

              <Grid item xs={2} xl={2} lg={2} >
              <div className='btn_back_innrepage'>
                <Link className='linkStyle' to='/services'>
                  <Button
                    title='GO BACK'
                    style={{ fontSize: '22px', backgroundColor: '#fff', color: '#7266FB', fontFamily: 'Roboto-Medium',}}
                    widthHeight={[270, 70]}
                    variant='outlined'
                    icon={<ArrowBackIcon />}
                  />
                </Link>

                <Link className='linkStyle' to='/home'>
                  <Button
                    title='HOME'
                    style={{ fontSize: '22px', backgroundColor: '#fff', color: '#7266FB', fontFamily: 'Roboto-Medium',}}
                    widthHeight={[270, 70]}
                    variant='outlined'
                    icon={<HomeRoundedIcon />}
                  />
                </Link>
                </div>
              </Grid>

              <Grid item xs={4} xl={4} lg={4} style={{ marginLeft: 0, marginTop: 0 }}>
                <div className='services'>
                  <div className='page_link'>
                    <Link to='/home'>{<HomeRoundedIcon />} Home</Link>
                    <span><i className='fas fa-chevron-right' /></span>
                    <Link to='/services'>Services</Link>
                    <span><i className='fas fa-chevron-right' /></span>
                    <Link className="page_active" to='/services-details'>Details</Link>
                  </div>
                </div>
                <div class='service_details'>

                  <img src={data.image} />
                  <div class='services_details_cont'>
                    <h1>{data.title}</h1>
                  </div>

                </div>
              </Grid>
              <Grid item xs={6} xl={6} lg={6} style={{ marginLeft: 0, marginRight: 0 }}>
                <div class='services white_text'>
                  {/* <p>{markdowntoreact(JSON.parse(data.description))}</p> */}
                  <p>{data.description}</p>
                </div>
                <div className='donation_wgts pattren_3'>
                  <div>
                    <h1>Make a Donation</h1>
                    <div className='donation-box'>
                      <p>Your generous donation will help us to continue and add more awesome services insha’Allah</p>
                    </div>
                  </div>
                  <div class='btn-div'>
                    <Link className='wgts_readmore' to='/donation'>let's Donate</Link>
                  </div>
                </div>
              </Grid>
              
            </Grid>
          </div>
     
      </div>

    </div>

  )
}

export default ServicesDetails
