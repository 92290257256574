import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/effect-flip/effect-flip.min.css"

import "./style.css";

import SwiperCore, {
    EffectFade,Navigation,Pagination, EffectFlip, Autoplay
  } from 'swiper/core';

  
SwiperCore.use([Autoplay, EffectFade,EffectFlip, Navigation,Pagination]);

export default function BannerSlider(props) {
    const {bannerCard} = props;
    let transition = bannerCard?.transitions ?? "";

    let bannerTransition = "";
    let direction = false;
    if (transition === "fade" || transition === "dissolve") {
        bannerTransition = 'fade';
    } else if (transition === "flip horizontal") {
        bannerTransition = 'flip';
    } else if (transition === "flip vertical") {
        direction = "vertical";
        bannerTransition = 'flip';
    } else if (transition === "slide up") {
        bannerTransition = '';
        direction = "vertical";
    } else if (transition === "slide down") {
        bannerTransition = '';
        direction = "vertical";
    }

    console.log(bannerTransition, direction)

    return (
        <div >
            {bannerCard?.slider?.length ? <Swiper 
                spaceBetween={30} 
                effect={bannerTransition} 
                navigation={true} 
                className="mySwiper"
                // direction={direction}
                pagination={false}
                autoplay={{
                    "delay": 5000,
                    "disableOnInteraction": false
                }}
                {...(direction ? { direction: direction } : {})}
            >

                {bannerCard.slider.map((slide, i) => {
                    return <SwiperSlide key={i}><img src={slide.url} alt={slide.name} /></SwiperSlide>
                })}

            </Swiper> : null}
        </div>
    )
}
