import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { createFilter,createBlacklistFilter  } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import userReducer from './users/reducers'
import amountReducer from './payment/reducers'

const rootReducer = combineReducers({
  users: userReducer,
  payment: amountReducer
})
const filterStates = createFilter(
  'users',
  ['orgId', 'token','orgdata']
);
// const filterPayment = createBlacklistFilter(
//   'payment',
//   ['step']
// );
const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    filterStates,
    // filterPayment
  ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

export let persistor = persistStore(store)
export default store
