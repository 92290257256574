import React, { Component } from 'react'
import Moment from 'moment'
import { withRouter } from 'react-router-dom'
import Hijri from 'moment-hijri'
import { connect } from 'react-redux'
import { updateuserToken, orgId, orgData ,isHeader} from '../../store/users/actions'

import './header.css'
import HeaderLogo from '.././../assets/img/gsw.png'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      time: 0,
      isHeader: false,
      orgdata: ''
    }
  }

  componentDidMount() {

    this.setState({orgdata : this.props.orgdata})
    this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000)
    Hijri.locale('en')

    if(window.location.pathname === '/' || window.location.pathname === '/onboarding'
    || window.location.pathname === '/onboarding/welcome' || window.location.pathname === '/pause'){
      this.props.isHeader(false)
    }else {
      this.props.isHeader(true)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getDate() {
    Moment().format('h:mm:s')
  }

  render() {
    const { showHeader, homeScreen } = this.props;
    const orgdata = homeScreen?.layout?.headerCard ?? {};
    const tex_exemption = orgdata?.taxExemptStatus;
    const name = orgdata?.orgName
    const icon = orgdata?.orgIcon?.iconUrl;
    
      return (
      <>
        {showHeader && <div className="header_wrapper">

          <div className='header-background'> </div>

            <div className='kiosk_container'>
              <a className='top_logo' href='#'>
               {icon &&
               <img src={icon} alt={icon} />}
                <div className='logo_name'>
                  <h1>{name}</h1>
                  {tex_exemption && <p>A 501(C) not-for-profit religious organization</p>}
                </div>
              </a>

              <div className='header_right_col'>
                <div className='header_slah_titme'>
                  {orgdata?.americanDate && <div className='sl_time'>{Moment().format('dddd, MMM D, YYYY')}</div>}
                  {orgdata?.islamicDate && <div className='sl_time'><span>{Hijri().format('iMMMM iD, iYYYY')} AH</span></div>}
                  <div className='sl_date' />
                </div>
                <div className="header_vr_border"></div>
                <div className='date_titme'>
                  <div className='dt_time'>{Moment().format('h:mm A')}</div>
                </div>

              </div>
              <div className='clearfix' />
            </div>

        </div>}

      </>
    )
  }
}


function mapStateToProps(state) {
  return {
    orgdata: state.users.orgdata,
    showHeader : state.users.showHeader,
    homeScreen: state.users.homeScreen,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateuserToken: (token) => dispatch(updateuserToken(token)),
    orgId: (id) => dispatch(orgId(id)),
    orgData: (orgdata) => dispatch(orgData(orgdata)),
    isHeader: (boolean) => dispatch(isHeader(boolean)),


  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Header)