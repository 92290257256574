import React from 'react'
import { Link } from 'react-router-dom'

import Card from './Card'
import './style.css'
import './upcomingEvent.css'
import Moment from 'moment'

function upcomingEvent(props) {
  const { eventResponse } = props;
  return (
    <>
      <Card title='UPCOMING EVENTS' showFooter navigateTo='events'>
        <div className='ev_cont event'>
          {eventResponse && eventResponse.slice(0, 2).map((item, index) => {
            return <div key={index} style={index === 1 ? { display: 'flex', justifyContent: 'space-between' } : {}}>
              {index === 0 && <div className='up_top_text'>{Moment(item?.eventStart?.startAt).format('dddd D, YYYY, hh:mm A')}</div>}
              <h2>{item.title}</h2>
              <div className='up_col'>
                <ul className='ellipse'>
                  {index === 0 && item.speakers.map((list, index) => {
                    let speakerObj = list?.id?._id ? list.id : null;
                    return speakerObj ? <li className={`${index % 2 === 0 ? 'even' : 'odd'}`} key={index}>
                      <img key={index} src={speakerObj?.imageMetaData?.imageUrl} alt="speaker" />
                    </li> : null
                  })}
                </ul>
                <Link to={{
                  pathname: '/event-details',
                  state: {
                    eventdetailData: item
                  }
                }}>
                  <div className='event_date'>{Moment(item?.eventStart?.startAt).format('D')} <span>{Moment(item?.eventStart?.startAt).format('MMM')}</span></div>
                </Link>

              </div>
              <div className="up_event_border"></div>
            </div>
          })}
          {/* <div className='up_top_text'>{evtTime}</div>
          <h2>{evtName1}</h2>
          <div className='up_col'>
            <ul className='ellipse'>
              <li className='even'><a href='#'><img src={require('../../assets/rawimg/Ellipse_1.png')} /></a></li>
              <li className='odd'><a href='#'><img src={require('../../assets/rawimg/Ellipse_2.png')} /></a></li>
              <li className='even'><a href='#'><img src={require('../../assets/rawimg/Ellipse_3.png')} /></a></li>
            </ul>
            <div className='event_date'>{evtDate1} <span>{evtMonth1}</span></div>

          </div>
          <div className='saprator' />
          <div className='up_col'>
            <h2>{evtName2}</h2>
            <div className='event_date'>{evtDate2} <span>{evtMonth2}</span></div>

          </div> */}
        </div>

      </Card>
      

    </>

  )
}

export default upcomingEvent
